const TimeZones = {
    "Africa/Bamako": {
        "display_text": "(GMT+0:00) Africa/Bamako",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Africa/Banjul": {
        "display_text": "(GMT+0:00) Africa/Banjul",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Africa/Conakry": {
        "display_text": "(GMT+0:00) Africa/Conakry",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Africa/Dakar": {
        "display_text": "(GMT+0:00) Africa/Dakar",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Africa/Freetown": {
        "display_text": "(GMT+0:00) Africa/Freetown",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Africa/Lome": {
        "display_text": "(GMT+0:00) Africa/Lome",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Africa/Nouakchott": {
        "display_text": "(GMT+0:00) Africa/Nouakchott",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Africa/Ouagadougou": {
        "display_text": "(GMT+0:00) Africa/Ouagadougou",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Africa/Timbuktu": {
        "display_text": "(GMT+0:00) Africa/Timbuktu",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Atlantic/St_Helena": {
        "display_text": "(GMT+0:00) Atlantic/St_Helena",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Egypt": {
        "display_text": "(GMT+2:00) Egypt",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Africa/Maseru": {
        "display_text": "(GMT+2:00) Africa/Maseru",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Africa/Mbabane": {
        "display_text": "(GMT+2:00) Africa/Mbabane",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Africa/Bangui": {
        "display_text": "(GMT+1:00) Africa/Bangui",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Africa/Brazzaville": {
        "display_text": "(GMT+1:00) Africa/Brazzaville",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Africa/Douala": {
        "display_text": "(GMT+1:00) Africa/Douala",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Africa/Kinshasa": {
        "display_text": "(GMT+1:00) Africa/Kinshasa",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Africa/Libreville": {
        "display_text": "(GMT+1:00) Africa/Libreville",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Africa/Luanda": {
        "display_text": "(GMT+1:00) Africa/Luanda",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Africa/Malabo": {
        "display_text": "(GMT+1:00) Africa/Malabo",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Africa/Niamey": {
        "display_text": "(GMT+1:00) Africa/Niamey",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Africa/Porto-Novo": {
        "display_text": "(GMT+1:00) Africa/Porto-Novo",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Africa/Blantyre": {
        "display_text": "(GMT+2:00) Africa/Blantyre",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Africa/Bujumbura": {
        "display_text": "(GMT+2:00) Africa/Bujumbura",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Africa/Gaborone": {
        "display_text": "(GMT+2:00) Africa/Gaborone",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Africa/Harare": {
        "display_text": "(GMT+2:00) Africa/Harare",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Africa/Kigali": {
        "display_text": "(GMT+2:00) Africa/Kigali",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Africa/Lubumbashi": {
        "display_text": "(GMT+2:00) Africa/Lubumbashi",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Africa/Lusaka": {
        "display_text": "(GMT+2:00) Africa/Lusaka",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Africa/Addis_Ababa": {
        "display_text": "(GMT+3:00) Africa/Addis_Ababa",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Africa/Asmara": {
        "display_text": "(GMT+3:00) Africa/Asmara",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Africa/Asmera": {
        "display_text": "(GMT+3:00) Africa/Asmera",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Africa/Dar_es_Salaam": {
        "display_text": "(GMT+3:00) Africa/Dar_es_Salaam",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Africa/Djibouti": {
        "display_text": "(GMT+3:00) Africa/Djibouti",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Africa/Kampala": {
        "display_text": "(GMT+3:00) Africa/Kampala",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Africa/Mogadishu": {
        "display_text": "(GMT+3:00) Africa/Mogadishu",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Indian/Antananarivo": {
        "display_text": "(GMT+3:00) Indian/Antananarivo",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Indian/Comoro": {
        "display_text": "(GMT+3:00) Indian/Comoro",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Indian/Mayotte": {
        "display_text": "(GMT+3:00) Indian/Mayotte",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Libya": {
        "display_text": "(GMT+2:00) Libya",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "America/Atka": {
        "display_text": "(GMT-10:00) America/Atka",
        "offset": -10.0,
        "gmt": "GMT-10:00"
    },
    "US/Aleutian": {
        "display_text": "(GMT-10:00) US/Aleutian",
        "offset": -10.0,
        "gmt": "GMT-10:00"
    },
    "US/Alaska": {
        "display_text": "(GMT-9:00) US/Alaska",
        "offset": -9.0,
        "gmt": "GMT-9:00"
    },
    "America/Buenos_Aires": {
        "display_text": "(GMT-3:00) America/Buenos_Aires",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Argentina/ComodRivadavia": {
        "display_text": "(GMT-3:00) America/Argentina/ComodRivadavia",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Catamarca": {
        "display_text": "(GMT-3:00) America/Catamarca",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Cordoba": {
        "display_text": "(GMT-3:00) America/Cordoba",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Rosario": {
        "display_text": "(GMT-3:00) America/Rosario",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Jujuy": {
        "display_text": "(GMT-3:00) America/Jujuy",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Mendoza": {
        "display_text": "(GMT-3:00) America/Mendoza",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Coral_Harbour": {
        "display_text": "(GMT-5:00) America/Coral_Harbour",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "US/Central": {
        "display_text": "(GMT-6:00) US/Central",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Aruba": {
        "display_text": "(GMT-4:00) America/Aruba",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Kralendijk": {
        "display_text": "(GMT-4:00) America/Kralendijk",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Lower_Princes": {
        "display_text": "(GMT-4:00) America/Lower_Princes",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Shiprock": {
        "display_text": "(GMT-7:00) America/Shiprock",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "Navajo": {
        "display_text": "(GMT-7:00) Navajo",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "US/Mountain": {
        "display_text": "(GMT-7:00) US/Mountain",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "US/Michigan": {
        "display_text": "(GMT-5:00) US/Michigan",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "Canada/Mountain": {
        "display_text": "(GMT-7:00) Canada/Mountain",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "America/Indiana/Indianapolis": {
        "display_text": "(GMT-5:00) America/Indiana/Indianapolis",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Indianapolis": {
        "display_text": "(GMT-5:00) America/Indianapolis",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "US/East-Indiana": {
        "display_text": "(GMT-5:00) US/East-Indiana",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Nuuk": {
        "display_text": "(GMT-2:00) America/Nuuk",
        "offset": -2.0,
        "gmt": "GMT-2:00"
    },
    "Canada/Atlantic": {
        "display_text": "(GMT-4:00) Canada/Atlantic",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "Cuba": {
        "display_text": "(GMT-5:00) Cuba",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Knox_IN": {
        "display_text": "(GMT-6:00) America/Knox_IN",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "US/Indiana-Starke": {
        "display_text": "(GMT-6:00) US/Indiana-Starke",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "Jamaica": {
        "display_text": "(GMT-5:00) Jamaica",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Louisville": {
        "display_text": "(GMT-5:00) America/Louisville",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "US/Pacific": {
        "display_text": "(GMT-8:00) US/Pacific",
        "offset": -8.0,
        "gmt": "GMT-8:00"
    },
    "Brazil/West": {
        "display_text": "(GMT-4:00) Brazil/West",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "Mexico/BajaSur": {
        "display_text": "(GMT-7:00) Mexico/BajaSur",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "Mexico/General": {
        "display_text": "(GMT-6:00) Mexico/General",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "US/Eastern": {
        "display_text": "(GMT-5:00) US/Eastern",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "Brazil/DeNoronha": {
        "display_text": "(GMT-2:00) Brazil/DeNoronha",
        "offset": -2.0,
        "gmt": "GMT-2:00"
    },
    "America/Cayman": {
        "display_text": "(GMT-5:00) America/Cayman",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "US/Arizona": {
        "display_text": "(GMT-7:00) US/Arizona",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "America/Anguilla": {
        "display_text": "(GMT-4:00) America/Anguilla",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Antigua": {
        "display_text": "(GMT-4:00) America/Antigua",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Dominica": {
        "display_text": "(GMT-4:00) America/Dominica",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Grenada": {
        "display_text": "(GMT-4:00) America/Grenada",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Guadeloupe": {
        "display_text": "(GMT-4:00) America/Guadeloupe",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Marigot": {
        "display_text": "(GMT-4:00) America/Marigot",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Montserrat": {
        "display_text": "(GMT-4:00) America/Montserrat",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/St_Barthelemy": {
        "display_text": "(GMT-4:00) America/St_Barthelemy",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/St_Kitts": {
        "display_text": "(GMT-4:00) America/St_Kitts",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/St_Lucia": {
        "display_text": "(GMT-4:00) America/St_Lucia",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/St_Thomas": {
        "display_text": "(GMT-4:00) America/St_Thomas",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/St_Vincent": {
        "display_text": "(GMT-4:00) America/St_Vincent",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Tortola": {
        "display_text": "(GMT-4:00) America/Tortola",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Virgin": {
        "display_text": "(GMT-4:00) America/Virgin",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "Canada/Saskatchewan": {
        "display_text": "(GMT-6:00) Canada/Saskatchewan",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Porto_Acre": {
        "display_text": "(GMT-5:00) America/Porto_Acre",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "Brazil/Acre": {
        "display_text": "(GMT-5:00) Brazil/Acre",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "Chile/Continental": {
        "display_text": "(GMT+1:00) Chile/Continental",
        "offset": -2.0,
        "gmt": "GMT+1:00"
    },
    "Brazil/East": {
        "display_text": "(GMT-3:00) Brazil/East",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "Canada/Newfoundland": {
        "display_text": "(GMT-3:30) Canada/Newfoundland",
        "offset": -3.5,
        "gmt": "GMT-3:30"
    },
    "America/Ensenada": {
        "display_text": "(GMT-8:00) America/Ensenada",
        "offset": -8.0,
        "gmt": "GMT-8:00"
    },
    "America/Santa_Isabel": {
        "display_text": "(GMT-8:00) America/Santa_Isabel",
        "offset": -8.0,
        "gmt": "GMT-8:00"
    },
    "Mexico/BajaNorte": {
        "display_text": "(GMT-8:00) Mexico/BajaNorte",
        "offset": -8.0,
        "gmt": "GMT-8:00"
    },
    "America/Montreal": {
        "display_text": "(GMT-5:00) America/Montreal",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "Canada/Eastern": {
        "display_text": "(GMT-5:00) Canada/Eastern",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "Canada/Pacific": {
        "display_text": "(GMT-8:00) Canada/Pacific",
        "offset": -8.0,
        "gmt": "GMT-8:00"
    },
    "Canada/Yukon": {
        "display_text": "(GMT-7:00) Canada/Yukon",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "Canada/Central": {
        "display_text": "(GMT-6:00) Canada/Central",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "Asia/Ashkhabad": {
        "display_text": "(GMT+5:00) Asia/Ashkhabad",
        "offset": 5.0,
        "gmt": "GMT+5:00"
    },
    "Asia/Phnom_Penh": {
        "display_text": "(GMT+7:00) Asia/Phnom_Penh",
        "offset": 7.0,
        "gmt": "GMT+7:00"
    },
    "Asia/Vientiane": {
        "display_text": "(GMT+7:00) Asia/Vientiane",
        "offset": 7.0,
        "gmt": "GMT+7:00"
    },
    "Asia/Dacca": {
        "display_text": "(GMT+6:00) Asia/Dacca",
        "offset": 6.0,
        "gmt": "GMT+6:00"
    },
    "Asia/Muscat": {
        "display_text": "(GMT+4:00) Asia/Muscat",
        "offset": 4.0,
        "gmt": "GMT+4:00"
    },
    "Asia/Saigon": {
        "display_text": "(GMT+7:00) Asia/Saigon",
        "offset": 7.0,
        "gmt": "GMT+7:00"
    },
    "Hongkong": {
        "display_text": "(GMT+8:00) Hongkong",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Asia/Tel_Aviv": {
        "display_text": "(GMT+2:00) Asia/Tel_Aviv",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Israel": {
        "display_text": "(GMT+2:00) Israel",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Asia/Katmandu": {
        "display_text": "(GMT+5:45) Asia/Katmandu",
        "offset": 5.75,
        "gmt": "GMT+5:45"
    },
    "Asia/Calcutta": {
        "display_text": "(GMT+5:30) Asia/Calcutta",
        "offset": 5.5,
        "gmt": "GMT+5:30"
    },
    "Asia/Singapore": {
        "display_text": "(GMT+8:00) Asia/Singapore",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Singapore": {
        "display_text": "(GMT+8:00) Singapore",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Asia/Macao": {
        "display_text": "(GMT+8:00) Asia/Macao",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Asia/Ujung_Pandang": {
        "display_text": "(GMT+8:00) Asia/Ujung_Pandang",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Europe/Nicosia": {
        "display_text": "(GMT+2:00) Europe/Nicosia",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Asia/Bahrain": {
        "display_text": "(GMT+3:00) Asia/Bahrain",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Asia/Yangon": {
        "display_text": "(GMT+6:30) Asia/Yangon",
        "offset": 6.5,
        "gmt": "GMT+6:30"
    },
    "Asia/Aden": {
        "display_text": "(GMT+3:00) Asia/Aden",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Asia/Kuwait": {
        "display_text": "(GMT+3:00) Asia/Kuwait",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "ROK": {
        "display_text": "(GMT+9:00) ROK",
        "offset": 9.0,
        "gmt": "GMT+9:00"
    },
    "Asia/Chongqing": {
        "display_text": "(GMT+8:00) Asia/Chongqing",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Asia/Chungking": {
        "display_text": "(GMT+8:00) Asia/Chungking",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Asia/Harbin": {
        "display_text": "(GMT+8:00) Asia/Harbin",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "PRC": {
        "display_text": "(GMT+8:00) PRC",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Iran": {
        "display_text": "(GMT+3:30) Iran",
        "offset": 3.5,
        "gmt": "GMT+3:30"
    },
    "Asia/Thimbu": {
        "display_text": "(GMT+6:00) Asia/Thimbu",
        "offset": 6.0,
        "gmt": "GMT+6:00"
    },
    "Japan": {
        "display_text": "(GMT+9:00) Japan",
        "offset": 9.0,
        "gmt": "GMT+9:00"
    },
    "Asia/Ulan_Bator": {
        "display_text": "(GMT+8:00) Asia/Ulan_Bator",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Asia/Kashgar": {
        "display_text": "(GMT+6:00) Asia/Kashgar",
        "offset": 6.0,
        "gmt": "GMT+6:00"
    },
    "Atlantic/Faeroe": {
        "display_text": "(GMT+0:00) Atlantic/Faeroe",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Iceland": {
        "display_text": "(GMT+0:00) Iceland",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Etc/GMT+2": {
        "display_text": "(GMT-2:00) Etc/GMT+2",
        "offset": -2.0,
        "gmt": "GMT-2:00"
    },
    "Australia/South": {
        "display_text": "(GMT+1:00) Australia/South",
        "offset": 11.5,
        "gmt": "GMT+1:00"
    },
    "Australia/Queensland": {
        "display_text": "(GMT+10:00) Australia/Queensland",
        "offset": 10.0,
        "gmt": "GMT+10:00"
    },
    "Australia/Yancowinna": {
        "display_text": "(GMT+1:00) Australia/Yancowinna",
        "offset": 11.5,
        "gmt": "GMT+1:00"
    },
    "Australia/North": {
        "display_text": "(GMT+9:30) Australia/North",
        "offset": 9.5,
        "gmt": "GMT+9:30"
    },
    "Australia/Tasmania": {
        "display_text": "(GMT+1:00) Australia/Tasmania",
        "offset": 12.0,
        "gmt": "GMT+1:00"
    },
    "Australia/LHI": {
        "display_text": "(GMT+0:30) Australia/LHI",
        "offset": 11.5,
        "gmt": "GMT+0:30"
    },
    "Australia/Victoria": {
        "display_text": "(GMT+1:00) Australia/Victoria",
        "offset": 12.0,
        "gmt": "GMT+1:00"
    },
    "Australia/West": {
        "display_text": "(GMT+8:00) Australia/West",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Australia/ACT": {
        "display_text": "(GMT+1:00) Australia/ACT",
        "offset": 12.0,
        "gmt": "GMT+1:00"
    },
    "Australia/Canberra": {
        "display_text": "(GMT+1:00) Australia/Canberra",
        "offset": 12.0,
        "gmt": "GMT+1:00"
    },
    "Australia/NSW": {
        "display_text": "(GMT+1:00) Australia/NSW",
        "offset": 12.0,
        "gmt": "GMT+1:00"
    },
    "Etc/GMT": {
        "display_text": "(GMT+0:00) Etc/GMT",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Etc/GMT+0": {
        "display_text": "(GMT+0:00) Etc/GMT+0",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Etc/GMT0": {
        "display_text": "(GMT+0:00) Etc/GMT0",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Etc/Greenwich": {
        "display_text": "(GMT+0:00) Etc/Greenwich",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "GMT+0": {
        "display_text": "(GMT+0:00) GMT+0",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "GMT-0": {
        "display_text": "(GMT+0:00) GMT-0",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "GMT0": {
        "display_text": "(GMT+0:00) GMT0",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Greenwich": {
        "display_text": "(GMT+0:00) Greenwich",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Etc/UCT": {
        "display_text": "(GMT+0:00) Etc/UCT",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Etc/Universal": {
        "display_text": "(GMT+0:00) Etc/Universal",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Etc/Zulu": {
        "display_text": "(GMT+0:00) Etc/Zulu",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "UCT": {
        "display_text": "(GMT+0:00) UCT",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Etc/UTC": {
        "display_text": "(GMT+0:00) Etc/UTC",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Universal": {
        "display_text": "(GMT+0:00) Universal",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Zulu": {
        "display_text": "(GMT+0:00) Zulu",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Europe/Ljubljana": {
        "display_text": "(GMT+1:00) Europe/Ljubljana",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Podgorica": {
        "display_text": "(GMT+1:00) Europe/Podgorica",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Sarajevo": {
        "display_text": "(GMT+1:00) Europe/Sarajevo",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Skopje": {
        "display_text": "(GMT+1:00) Europe/Skopje",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Zagreb": {
        "display_text": "(GMT+1:00) Europe/Zagreb",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Tiraspol": {
        "display_text": "(GMT+2:00) Europe/Tiraspol",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Eire": {
        "display_text": "(GMT+0:00) Eire",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Europe/Mariehamn": {
        "display_text": "(GMT+2:00) Europe/Mariehamn",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Asia/Istanbul": {
        "display_text": "(GMT+3:00) Asia/Istanbul",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Turkey": {
        "display_text": "(GMT+3:00) Turkey",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Portugal": {
        "display_text": "(GMT+0:00) Portugal",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Europe/Belfast": {
        "display_text": "(GMT+0:00) Europe/Belfast",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Europe/Guernsey": {
        "display_text": "(GMT+0:00) Europe/Guernsey",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Europe/Isle_of_Man": {
        "display_text": "(GMT+0:00) Europe/Isle_of_Man",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Europe/Jersey": {
        "display_text": "(GMT+0:00) Europe/Jersey",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "GB": {
        "display_text": "(GMT+0:00) GB",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "GB-Eire": {
        "display_text": "(GMT+0:00) GB-Eire",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "W-SU": {
        "display_text": "(GMT+3:00) W-SU",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Arctic/Longyearbyen": {
        "display_text": "(GMT+1:00) Arctic/Longyearbyen",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Atlantic/Jan_Mayen": {
        "display_text": "(GMT+1:00) Atlantic/Jan_Mayen",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Bratislava": {
        "display_text": "(GMT+1:00) Europe/Bratislava",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/San_Marino": {
        "display_text": "(GMT+1:00) Europe/San_Marino",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Vatican": {
        "display_text": "(GMT+1:00) Europe/Vatican",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Poland": {
        "display_text": "(GMT+1:00) Poland",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Busingen": {
        "display_text": "(GMT+1:00) Europe/Busingen",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Vaduz": {
        "display_text": "(GMT+1:00) Europe/Vaduz",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Etc/GMT-7": {
        "display_text": "(GMT+7:00) Etc/GMT-7",
        "offset": 7.0,
        "gmt": "GMT+7:00"
    },
    "Antarctica/McMurdo": {
        "display_text": "(GMT+1:00) Antarctica/McMurdo",
        "offset": 14.0,
        "gmt": "GMT+1:00"
    },
    "Antarctica/South_Pole": {
        "display_text": "(GMT+1:00) Antarctica/South_Pole",
        "offset": 14.0,
        "gmt": "GMT+1:00"
    },
    "NZ": {
        "display_text": "(GMT+1:00) NZ",
        "offset": 14.0,
        "gmt": "GMT+1:00"
    },
    "NZ-CHAT": {
        "display_text": "(GMT+1:00) NZ-CHAT",
        "offset": 14.75,
        "gmt": "GMT+1:00"
    },
    "Pacific/Truk": {
        "display_text": "(GMT+10:00) Pacific/Truk",
        "offset": 10.0,
        "gmt": "GMT+10:00"
    },
    "Pacific/Yap": {
        "display_text": "(GMT+10:00) Pacific/Yap",
        "offset": 10.0,
        "gmt": "GMT+10:00"
    },
    "Chile/EasterIsland": {
        "display_text": "(GMT+1:00) Chile/EasterIsland",
        "offset": -4.0,
        "gmt": "GMT+1:00"
    },
    "Pacific/Saipan": {
        "display_text": "(GMT+10:00) Pacific/Saipan",
        "offset": 10.0,
        "gmt": "GMT+10:00"
    },
    "Pacific/Johnston": {
        "display_text": "(GMT-10:00) Pacific/Johnston",
        "offset": -10.0,
        "gmt": "GMT-10:00"
    },
    "US/Hawaii": {
        "display_text": "(GMT-10:00) US/Hawaii",
        "offset": -10.0,
        "gmt": "GMT-10:00"
    },
    "Kwajalein": {
        "display_text": "(GMT+12:00) Kwajalein",
        "offset": 12.0,
        "gmt": "GMT+12:00"
    },
    "Pacific/Midway": {
        "display_text": "(GMT-11:00) Pacific/Midway",
        "offset": -11.0,
        "gmt": "GMT-11:00"
    },
    "Pacific/Samoa": {
        "display_text": "(GMT-11:00) Pacific/Samoa",
        "offset": -11.0,
        "gmt": "GMT-11:00"
    },
    "US/Samoa": {
        "display_text": "(GMT-11:00) US/Samoa",
        "offset": -11.0,
        "gmt": "GMT-11:00"
    },
    "Etc/GMT-9": {
        "display_text": "(GMT+9:00) Etc/GMT-9",
        "offset": 9.0,
        "gmt": "GMT+9:00"
    },
    "Pacific/Ponape": {
        "display_text": "(GMT+11:00) Pacific/Ponape",
        "offset": 11.0,
        "gmt": "GMT+11:00"
    },
    "Etc/GMT-10": {
        "display_text": "(GMT+10:00) Etc/GMT-10",
        "offset": 10.0,
        "gmt": "GMT+10:00"
    },
    "Etc/GMT-12": {
        "display_text": "(GMT+12:00) Etc/GMT-12",
        "offset": 12.0,
        "gmt": "GMT+12:00"
    },
    "Pacific/Funafuti": {
        "display_text": "(GMT+12:00) Pacific/Funafuti",
        "offset": 12.0,
        "gmt": "GMT+12:00"
    },
    "Pacific/Wake": {
        "display_text": "(GMT+12:00) Pacific/Wake",
        "offset": 12.0,
        "gmt": "GMT+12:00"
    },
    "Pacific/Wallis": {
        "display_text": "(GMT+12:00) Pacific/Wallis",
        "offset": 12.0,
        "gmt": "GMT+12:00"
    },
    "Africa/Abidjan": {
        "display_text": "(GMT+0:00) Africa/Abidjan",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Africa/Accra": {
        "display_text": "(GMT+0:00) Africa/Accra",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Africa/Nairobi": {
        "display_text": "(GMT+3:00) Africa/Nairobi",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Africa/Algiers": {
        "display_text": "(GMT+1:00) Africa/Algiers",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Africa/Lagos": {
        "display_text": "(GMT+1:00) Africa/Lagos",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Africa/Bissau": {
        "display_text": "(GMT+0:00) Africa/Bissau",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Africa/Maputo": {
        "display_text": "(GMT+2:00) Africa/Maputo",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Africa/Cairo": {
        "display_text": "(GMT+2:00) Africa/Cairo",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Africa/Casablanca": {
        "display_text": "(GMT+1:00) Africa/Casablanca",
        "offset": 2.0,
        "gmt": "GMT+1:00"
    },
    "Africa/Ceuta": {
        "display_text": "(GMT+1:00) Africa/Ceuta",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Africa/El_Aaiun": {
        "display_text": "(GMT+1:00) Africa/El_Aaiun",
        "offset": 2.0,
        "gmt": "GMT+1:00"
    },
    "Africa/Johannesburg": {
        "display_text": "(GMT+2:00) Africa/Johannesburg",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Africa/Juba": {
        "display_text": "(GMT+2:00) Africa/Juba",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Africa/Khartoum": {
        "display_text": "(GMT+2:00) Africa/Khartoum",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Africa/Monrovia": {
        "display_text": "(GMT+0:00) Africa/Monrovia",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Africa/Ndjamena": {
        "display_text": "(GMT+1:00) Africa/Ndjamena",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Africa/Sao_Tome": {
        "display_text": "(GMT+0:00) Africa/Sao_Tome",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Africa/Tripoli": {
        "display_text": "(GMT+2:00) Africa/Tripoli",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Africa/Tunis": {
        "display_text": "(GMT+1:00) Africa/Tunis",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Africa/Windhoek": {
        "display_text": "(GMT+1:00) Africa/Windhoek",
        "offset": 3.0,
        "gmt": "GMT+1:00"
    },
    "America/Adak": {
        "display_text": "(GMT-10:00) America/Adak",
        "offset": -10.0,
        "gmt": "GMT-10:00"
    },
    "America/Anchorage": {
        "display_text": "(GMT-9:00) America/Anchorage",
        "offset": -9.0,
        "gmt": "GMT-9:00"
    },
    "America/Port_of_Spain": {
        "display_text": "(GMT-4:00) America/Port_of_Spain",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Araguaina": {
        "display_text": "(GMT-3:00) America/Araguaina",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Argentina/Buenos_Aires": {
        "display_text": "(GMT-3:00) America/Argentina/Buenos_Aires",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Argentina/Catamarca": {
        "display_text": "(GMT-3:00) America/Argentina/Catamarca",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Argentina/Cordoba": {
        "display_text": "(GMT-3:00) America/Argentina/Cordoba",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Argentina/Jujuy": {
        "display_text": "(GMT-3:00) America/Argentina/Jujuy",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Argentina/La_Rioja": {
        "display_text": "(GMT-3:00) America/Argentina/La_Rioja",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Argentina/Mendoza": {
        "display_text": "(GMT-3:00) America/Argentina/Mendoza",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Argentina/Rio_Gallegos": {
        "display_text": "(GMT-3:00) America/Argentina/Rio_Gallegos",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Argentina/Salta": {
        "display_text": "(GMT-3:00) America/Argentina/Salta",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Argentina/San_Juan": {
        "display_text": "(GMT-3:00) America/Argentina/San_Juan",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Argentina/San_Luis": {
        "display_text": "(GMT-3:00) America/Argentina/San_Luis",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Argentina/Tucuman": {
        "display_text": "(GMT-3:00) America/Argentina/Tucuman",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Argentina/Ushuaia": {
        "display_text": "(GMT-3:00) America/Argentina/Ushuaia",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Curacao": {
        "display_text": "(GMT-4:00) America/Curacao",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Asuncion": {
        "display_text": "(GMT+1:00) America/Asuncion",
        "offset": -2.0,
        "gmt": "GMT+1:00"
    },
    "America/Atikokan": {
        "display_text": "(GMT-5:00) America/Atikokan",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Bahia_Banderas": {
        "display_text": "(GMT-6:00) America/Bahia_Banderas",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Bahia": {
        "display_text": "(GMT-3:00) America/Bahia",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Barbados": {
        "display_text": "(GMT-4:00) America/Barbados",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Belem": {
        "display_text": "(GMT-3:00) America/Belem",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Belize": {
        "display_text": "(GMT-6:00) America/Belize",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Blanc-Sablon": {
        "display_text": "(GMT-4:00) America/Blanc-Sablon",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Boa_Vista": {
        "display_text": "(GMT-4:00) America/Boa_Vista",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Bogota": {
        "display_text": "(GMT-5:00) America/Bogota",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Boise": {
        "display_text": "(GMT-7:00) America/Boise",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "America/Cambridge_Bay": {
        "display_text": "(GMT-7:00) America/Cambridge_Bay",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "America/Campo_Grande": {
        "display_text": "(GMT-4:00) America/Campo_Grande",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Cancun": {
        "display_text": "(GMT-5:00) America/Cancun",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Caracas": {
        "display_text": "(GMT-4:00) America/Caracas",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Cayenne": {
        "display_text": "(GMT-3:00) America/Cayenne",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Panama": {
        "display_text": "(GMT-5:00) America/Panama",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Chicago": {
        "display_text": "(GMT-6:00) America/Chicago",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Chihuahua": {
        "display_text": "(GMT-6:00) America/Chihuahua",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Costa_Rica": {
        "display_text": "(GMT-6:00) America/Costa_Rica",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Creston": {
        "display_text": "(GMT-7:00) America/Creston",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "America/Cuiaba": {
        "display_text": "(GMT-4:00) America/Cuiaba",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Danmarkshavn": {
        "display_text": "(GMT+0:00) America/Danmarkshavn",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "America/Dawson_Creek": {
        "display_text": "(GMT-7:00) America/Dawson_Creek",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "America/Dawson": {
        "display_text": "(GMT-7:00) America/Dawson",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "America/Denver": {
        "display_text": "(GMT-7:00) America/Denver",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "America/Detroit": {
        "display_text": "(GMT-5:00) America/Detroit",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Edmonton": {
        "display_text": "(GMT-7:00) America/Edmonton",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "America/Eirunepe": {
        "display_text": "(GMT-5:00) America/Eirunepe",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/El_Salvador": {
        "display_text": "(GMT-6:00) America/El_Salvador",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Tijuana": {
        "display_text": "(GMT-8:00) America/Tijuana",
        "offset": -8.0,
        "gmt": "GMT-8:00"
    },
    "America/Fort_Nelson": {
        "display_text": "(GMT-7:00) America/Fort_Nelson",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "America/Fort_Wayne": {
        "display_text": "(GMT-5:00) America/Fort_Wayne",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Fortaleza": {
        "display_text": "(GMT-3:00) America/Fortaleza",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Glace_Bay": {
        "display_text": "(GMT-4:00) America/Glace_Bay",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Godthab": {
        "display_text": "(GMT-2:00) America/Godthab",
        "offset": -2.0,
        "gmt": "GMT-2:00"
    },
    "America/Goose_Bay": {
        "display_text": "(GMT-4:00) America/Goose_Bay",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Grand_Turk": {
        "display_text": "(GMT-5:00) America/Grand_Turk",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Guatemala": {
        "display_text": "(GMT-6:00) America/Guatemala",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Guayaquil": {
        "display_text": "(GMT-5:00) America/Guayaquil",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Guyana": {
        "display_text": "(GMT-4:00) America/Guyana",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Halifax": {
        "display_text": "(GMT-4:00) America/Halifax",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Havana": {
        "display_text": "(GMT-5:00) America/Havana",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Hermosillo": {
        "display_text": "(GMT-7:00) America/Hermosillo",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "America/Indiana/Knox": {
        "display_text": "(GMT-6:00) America/Indiana/Knox",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Indiana/Marengo": {
        "display_text": "(GMT-5:00) America/Indiana/Marengo",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Indiana/Petersburg": {
        "display_text": "(GMT-5:00) America/Indiana/Petersburg",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Indiana/Tell_City": {
        "display_text": "(GMT-6:00) America/Indiana/Tell_City",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Indiana/Vevay": {
        "display_text": "(GMT-5:00) America/Indiana/Vevay",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Indiana/Vincennes": {
        "display_text": "(GMT-5:00) America/Indiana/Vincennes",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Indiana/Winamac": {
        "display_text": "(GMT-5:00) America/Indiana/Winamac",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Inuvik": {
        "display_text": "(GMT-7:00) America/Inuvik",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "America/Iqaluit": {
        "display_text": "(GMT-5:00) America/Iqaluit",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Jamaica": {
        "display_text": "(GMT-5:00) America/Jamaica",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Juneau": {
        "display_text": "(GMT-9:00) America/Juneau",
        "offset": -9.0,
        "gmt": "GMT-9:00"
    },
    "America/Kentucky/Louisville": {
        "display_text": "(GMT-5:00) America/Kentucky/Louisville",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Kentucky/Monticello": {
        "display_text": "(GMT-5:00) America/Kentucky/Monticello",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/La_Paz": {
        "display_text": "(GMT-4:00) America/La_Paz",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Lima": {
        "display_text": "(GMT-5:00) America/Lima",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Los_Angeles": {
        "display_text": "(GMT-8:00) America/Los_Angeles",
        "offset": -8.0,
        "gmt": "GMT-8:00"
    },
    "America/Maceio": {
        "display_text": "(GMT-3:00) America/Maceio",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Managua": {
        "display_text": "(GMT-6:00) America/Managua",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Manaus": {
        "display_text": "(GMT-4:00) America/Manaus",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Martinique": {
        "display_text": "(GMT-4:00) America/Martinique",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Matamoros": {
        "display_text": "(GMT-6:00) America/Matamoros",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Mazatlan": {
        "display_text": "(GMT-7:00) America/Mazatlan",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "America/Menominee": {
        "display_text": "(GMT-6:00) America/Menominee",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Merida": {
        "display_text": "(GMT-6:00) America/Merida",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Metlakatla": {
        "display_text": "(GMT-9:00) America/Metlakatla",
        "offset": -9.0,
        "gmt": "GMT-9:00"
    },
    "America/Mexico_City": {
        "display_text": "(GMT-6:00) America/Mexico_City",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Miquelon": {
        "display_text": "(GMT-3:00) America/Miquelon",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Moncton": {
        "display_text": "(GMT-4:00) America/Moncton",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Monterrey": {
        "display_text": "(GMT-6:00) America/Monterrey",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Montevideo": {
        "display_text": "(GMT-3:00) America/Montevideo",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Toronto": {
        "display_text": "(GMT-5:00) America/Toronto",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Nassau": {
        "display_text": "(GMT-5:00) America/Nassau",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/New_York": {
        "display_text": "(GMT-5:00) America/New_York",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Nipigon": {
        "display_text": "(GMT-5:00) America/Nipigon",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Nome": {
        "display_text": "(GMT-9:00) America/Nome",
        "offset": -9.0,
        "gmt": "GMT-9:00"
    },
    "America/Noronha": {
        "display_text": "(GMT-2:00) America/Noronha",
        "offset": -2.0,
        "gmt": "GMT-2:00"
    },
    "America/North_Dakota/Beulah": {
        "display_text": "(GMT-6:00) America/North_Dakota/Beulah",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/North_Dakota/Center": {
        "display_text": "(GMT-6:00) America/North_Dakota/Center",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/North_Dakota/New_Salem": {
        "display_text": "(GMT-6:00) America/North_Dakota/New_Salem",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Ojinaga": {
        "display_text": "(GMT-6:00) America/Ojinaga",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Pangnirtung": {
        "display_text": "(GMT-5:00) America/Pangnirtung",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Paramaribo": {
        "display_text": "(GMT-3:00) America/Paramaribo",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Phoenix": {
        "display_text": "(GMT-7:00) America/Phoenix",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "America/Port-au-Prince": {
        "display_text": "(GMT-5:00) America/Port-au-Prince",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Rio_Branco": {
        "display_text": "(GMT-5:00) America/Rio_Branco",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Porto_Velho": {
        "display_text": "(GMT-4:00) America/Porto_Velho",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Puerto_Rico": {
        "display_text": "(GMT-4:00) America/Puerto_Rico",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Punta_Arenas": {
        "display_text": "(GMT-3:00) America/Punta_Arenas",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Rainy_River": {
        "display_text": "(GMT-6:00) America/Rainy_River",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Rankin_Inlet": {
        "display_text": "(GMT-6:00) America/Rankin_Inlet",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Recife": {
        "display_text": "(GMT-3:00) America/Recife",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Regina": {
        "display_text": "(GMT-6:00) America/Regina",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Resolute": {
        "display_text": "(GMT-6:00) America/Resolute",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Santarem": {
        "display_text": "(GMT-3:00) America/Santarem",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Santiago": {
        "display_text": "(GMT+1:00) America/Santiago",
        "offset": -2.0,
        "gmt": "GMT+1:00"
    },
    "America/Santo_Domingo": {
        "display_text": "(GMT-4:00) America/Santo_Domingo",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Sao_Paulo": {
        "display_text": "(GMT-3:00) America/Sao_Paulo",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "America/Scoresbysund": {
        "display_text": "(GMT-1:00) America/Scoresbysund",
        "offset": -1.0,
        "gmt": "GMT-1:00"
    },
    "America/Sitka": {
        "display_text": "(GMT-9:00) America/Sitka",
        "offset": -9.0,
        "gmt": "GMT-9:00"
    },
    "America/St_Johns": {
        "display_text": "(GMT-3:30) America/St_Johns",
        "offset": -3.5,
        "gmt": "GMT-3:30"
    },
    "America/Swift_Current": {
        "display_text": "(GMT-6:00) America/Swift_Current",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Tegucigalpa": {
        "display_text": "(GMT-6:00) America/Tegucigalpa",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Thule": {
        "display_text": "(GMT-4:00) America/Thule",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "America/Thunder_Bay": {
        "display_text": "(GMT-5:00) America/Thunder_Bay",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "America/Vancouver": {
        "display_text": "(GMT-8:00) America/Vancouver",
        "offset": -8.0,
        "gmt": "GMT-8:00"
    },
    "America/Whitehorse": {
        "display_text": "(GMT-7:00) America/Whitehorse",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "America/Winnipeg": {
        "display_text": "(GMT-6:00) America/Winnipeg",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "America/Yakutat": {
        "display_text": "(GMT-9:00) America/Yakutat",
        "offset": -9.0,
        "gmt": "GMT-9:00"
    },
    "America/Yellowknife": {
        "display_text": "(GMT-7:00) America/Yellowknife",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "Antarctica/Casey": {
        "display_text": "(GMT+11:00) Antarctica/Casey",
        "offset": 11.0,
        "gmt": "GMT+11:00"
    },
    "Antarctica/Davis": {
        "display_text": "(GMT+7:00) Antarctica/Davis",
        "offset": 7.0,
        "gmt": "GMT+7:00"
    },
    "Antarctica/DumontDUrville": {
        "display_text": "(GMT+10:00) Antarctica/DumontDUrville",
        "offset": 10.0,
        "gmt": "GMT+10:00"
    },
    "Antarctica/Macquarie": {
        "display_text": "(GMT+1:00) Antarctica/Macquarie",
        "offset": 12.0,
        "gmt": "GMT+1:00"
    },
    "Antarctica/Mawson": {
        "display_text": "(GMT+5:00) Antarctica/Mawson",
        "offset": 5.0,
        "gmt": "GMT+5:00"
    },
    "Pacific/Auckland": {
        "display_text": "(GMT+1:00) Pacific/Auckland",
        "offset": 14.0,
        "gmt": "GMT+1:00"
    },
    "Antarctica/Palmer": {
        "display_text": "(GMT-3:00) Antarctica/Palmer",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "Antarctica/Rothera": {
        "display_text": "(GMT-3:00) Antarctica/Rothera",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "Antarctica/Syowa": {
        "display_text": "(GMT+3:00) Antarctica/Syowa",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Antarctica/Troll": {
        "display_text": "(GMT+0:00) Antarctica/Troll",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Antarctica/Vostok": {
        "display_text": "(GMT+6:00) Antarctica/Vostok",
        "offset": 6.0,
        "gmt": "GMT+6:00"
    },
    "Europe/Oslo": {
        "display_text": "(GMT+1:00) Europe/Oslo",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Asia/Riyadh": {
        "display_text": "(GMT+3:00) Asia/Riyadh",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Asia/Almaty": {
        "display_text": "(GMT+6:00) Asia/Almaty",
        "offset": 6.0,
        "gmt": "GMT+6:00"
    },
    "Asia/Amman": {
        "display_text": "(GMT+3:00) Asia/Amman",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Asia/Anadyr": {
        "display_text": "(GMT+12:00) Asia/Anadyr",
        "offset": 12.0,
        "gmt": "GMT+12:00"
    },
    "Asia/Aqtau": {
        "display_text": "(GMT+5:00) Asia/Aqtau",
        "offset": 5.0,
        "gmt": "GMT+5:00"
    },
    "Asia/Aqtobe": {
        "display_text": "(GMT+5:00) Asia/Aqtobe",
        "offset": 5.0,
        "gmt": "GMT+5:00"
    },
    "Asia/Ashgabat": {
        "display_text": "(GMT+5:00) Asia/Ashgabat",
        "offset": 5.0,
        "gmt": "GMT+5:00"
    },
    "Asia/Atyrau": {
        "display_text": "(GMT+5:00) Asia/Atyrau",
        "offset": 5.0,
        "gmt": "GMT+5:00"
    },
    "Asia/Baghdad": {
        "display_text": "(GMT+3:00) Asia/Baghdad",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Asia/Qatar": {
        "display_text": "(GMT+3:00) Asia/Qatar",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Asia/Baku": {
        "display_text": "(GMT+4:00) Asia/Baku",
        "offset": 4.0,
        "gmt": "GMT+4:00"
    },
    "Asia/Bangkok": {
        "display_text": "(GMT+7:00) Asia/Bangkok",
        "offset": 7.0,
        "gmt": "GMT+7:00"
    },
    "Asia/Barnaul": {
        "display_text": "(GMT+7:00) Asia/Barnaul",
        "offset": 7.0,
        "gmt": "GMT+7:00"
    },
    "Asia/Beirut": {
        "display_text": "(GMT+2:00) Asia/Beirut",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Asia/Bishkek": {
        "display_text": "(GMT+6:00) Asia/Bishkek",
        "offset": 6.0,
        "gmt": "GMT+6:00"
    },
    "Asia/Brunei": {
        "display_text": "(GMT+8:00) Asia/Brunei",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Asia/Kolkata": {
        "display_text": "(GMT+5:30) Asia/Kolkata",
        "offset": 5.5,
        "gmt": "GMT+5:30"
    },
    "Asia/Chita": {
        "display_text": "(GMT+9:00) Asia/Chita",
        "offset": 9.0,
        "gmt": "GMT+9:00"
    },
    "Asia/Choibalsan": {
        "display_text": "(GMT+8:00) Asia/Choibalsan",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Asia/Shanghai": {
        "display_text": "(GMT+8:00) Asia/Shanghai",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Asia/Colombo": {
        "display_text": "(GMT+5:30) Asia/Colombo",
        "offset": 5.5,
        "gmt": "GMT+5:30"
    },
    "Asia/Dhaka": {
        "display_text": "(GMT+6:00) Asia/Dhaka",
        "offset": 6.0,
        "gmt": "GMT+6:00"
    },
    "Asia/Damascus": {
        "display_text": "(GMT+3:00) Asia/Damascus",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Asia/Dili": {
        "display_text": "(GMT+9:00) Asia/Dili",
        "offset": 9.0,
        "gmt": "GMT+9:00"
    },
    "Asia/Dubai": {
        "display_text": "(GMT+4:00) Asia/Dubai",
        "offset": 4.0,
        "gmt": "GMT+4:00"
    },
    "Asia/Dushanbe": {
        "display_text": "(GMT+5:00) Asia/Dushanbe",
        "offset": 5.0,
        "gmt": "GMT+5:00"
    },
    "Asia/Famagusta": {
        "display_text": "(GMT+2:00) Asia/Famagusta",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Asia/Gaza": {
        "display_text": "(GMT+2:00) Asia/Gaza",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Asia/Hebron": {
        "display_text": "(GMT+2:00) Asia/Hebron",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Asia/Ho_Chi_Minh": {
        "display_text": "(GMT+7:00) Asia/Ho_Chi_Minh",
        "offset": 7.0,
        "gmt": "GMT+7:00"
    },
    "Asia/Hong_Kong": {
        "display_text": "(GMT+8:00) Asia/Hong_Kong",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Asia/Hovd": {
        "display_text": "(GMT+7:00) Asia/Hovd",
        "offset": 7.0,
        "gmt": "GMT+7:00"
    },
    "Asia/Irkutsk": {
        "display_text": "(GMT+8:00) Asia/Irkutsk",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Europe/Istanbul": {
        "display_text": "(GMT+3:00) Europe/Istanbul",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Asia/Jakarta": {
        "display_text": "(GMT+7:00) Asia/Jakarta",
        "offset": 7.0,
        "gmt": "GMT+7:00"
    },
    "Asia/Jayapura": {
        "display_text": "(GMT+9:00) Asia/Jayapura",
        "offset": 9.0,
        "gmt": "GMT+9:00"
    },
    "Asia/Jerusalem": {
        "display_text": "(GMT+2:00) Asia/Jerusalem",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Asia/Kabul": {
        "display_text": "(GMT+4:30) Asia/Kabul",
        "offset": 4.5,
        "gmt": "GMT+4:30"
    },
    "Asia/Kamchatka": {
        "display_text": "(GMT+12:00) Asia/Kamchatka",
        "offset": 12.0,
        "gmt": "GMT+12:00"
    },
    "Asia/Karachi": {
        "display_text": "(GMT+5:00) Asia/Karachi",
        "offset": 5.0,
        "gmt": "GMT+5:00"
    },
    "Asia/Urumqi": {
        "display_text": "(GMT+6:00) Asia/Urumqi",
        "offset": 6.0,
        "gmt": "GMT+6:00"
    },
    "Asia/Kathmandu": {
        "display_text": "(GMT+5:45) Asia/Kathmandu",
        "offset": 5.75,
        "gmt": "GMT+5:45"
    },
    "Asia/Khandyga": {
        "display_text": "(GMT+9:00) Asia/Khandyga",
        "offset": 9.0,
        "gmt": "GMT+9:00"
    },
    "Asia/Krasnoyarsk": {
        "display_text": "(GMT+7:00) Asia/Krasnoyarsk",
        "offset": 7.0,
        "gmt": "GMT+7:00"
    },
    "Asia/Kuala_Lumpur": {
        "display_text": "(GMT+8:00) Asia/Kuala_Lumpur",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Asia/Kuching": {
        "display_text": "(GMT+8:00) Asia/Kuching",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Asia/Macau": {
        "display_text": "(GMT+8:00) Asia/Macau",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Asia/Magadan": {
        "display_text": "(GMT+11:00) Asia/Magadan",
        "offset": 11.0,
        "gmt": "GMT+11:00"
    },
    "Asia/Makassar": {
        "display_text": "(GMT+8:00) Asia/Makassar",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Asia/Manila": {
        "display_text": "(GMT+8:00) Asia/Manila",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Asia/Nicosia": {
        "display_text": "(GMT+2:00) Asia/Nicosia",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Asia/Novokuznetsk": {
        "display_text": "(GMT+7:00) Asia/Novokuznetsk",
        "offset": 7.0,
        "gmt": "GMT+7:00"
    },
    "Asia/Novosibirsk": {
        "display_text": "(GMT+7:00) Asia/Novosibirsk",
        "offset": 7.0,
        "gmt": "GMT+7:00"
    },
    "Asia/Omsk": {
        "display_text": "(GMT+6:00) Asia/Omsk",
        "offset": 6.0,
        "gmt": "GMT+6:00"
    },
    "Asia/Oral": {
        "display_text": "(GMT+5:00) Asia/Oral",
        "offset": 5.0,
        "gmt": "GMT+5:00"
    },
    "Asia/Pontianak": {
        "display_text": "(GMT+7:00) Asia/Pontianak",
        "offset": 7.0,
        "gmt": "GMT+7:00"
    },
    "Asia/Pyongyang": {
        "display_text": "(GMT+9:00) Asia/Pyongyang",
        "offset": 9.0,
        "gmt": "GMT+9:00"
    },
    "Asia/Qostanay": {
        "display_text": "(GMT+6:00) Asia/Qostanay",
        "offset": 6.0,
        "gmt": "GMT+6:00"
    },
    "Asia/Qyzylorda": {
        "display_text": "(GMT+5:00) Asia/Qyzylorda",
        "offset": 5.0,
        "gmt": "GMT+5:00"
    },
    "Asia/Rangoon": {
        "display_text": "(GMT+6:30) Asia/Rangoon",
        "offset": 6.5,
        "gmt": "GMT+6:30"
    },
    "Asia/Sakhalin": {
        "display_text": "(GMT+11:00) Asia/Sakhalin",
        "offset": 11.0,
        "gmt": "GMT+11:00"
    },
    "Asia/Samarkand": {
        "display_text": "(GMT+5:00) Asia/Samarkand",
        "offset": 5.0,
        "gmt": "GMT+5:00"
    },
    "Asia/Seoul": {
        "display_text": "(GMT+9:00) Asia/Seoul",
        "offset": 9.0,
        "gmt": "GMT+9:00"
    },
    "Asia/Srednekolymsk": {
        "display_text": "(GMT+11:00) Asia/Srednekolymsk",
        "offset": 11.0,
        "gmt": "GMT+11:00"
    },
    "Asia/Taipei": {
        "display_text": "(GMT+8:00) Asia/Taipei",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Asia/Tashkent": {
        "display_text": "(GMT+5:00) Asia/Tashkent",
        "offset": 5.0,
        "gmt": "GMT+5:00"
    },
    "Asia/Tbilisi": {
        "display_text": "(GMT+4:00) Asia/Tbilisi",
        "offset": 4.0,
        "gmt": "GMT+4:00"
    },
    "Asia/Tehran": {
        "display_text": "(GMT+3:30) Asia/Tehran",
        "offset": 3.5,
        "gmt": "GMT+3:30"
    },
    "Asia/Thimphu": {
        "display_text": "(GMT+6:00) Asia/Thimphu",
        "offset": 6.0,
        "gmt": "GMT+6:00"
    },
    "Asia/Tokyo": {
        "display_text": "(GMT+9:00) Asia/Tokyo",
        "offset": 9.0,
        "gmt": "GMT+9:00"
    },
    "Asia/Tomsk": {
        "display_text": "(GMT+7:00) Asia/Tomsk",
        "offset": 7.0,
        "gmt": "GMT+7:00"
    },
    "Asia/Ulaanbaatar": {
        "display_text": "(GMT+8:00) Asia/Ulaanbaatar",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Asia/Ust-Nera": {
        "display_text": "(GMT+10:00) Asia/Ust-Nera",
        "offset": 10.0,
        "gmt": "GMT+10:00"
    },
    "Asia/Vladivostok": {
        "display_text": "(GMT+10:00) Asia/Vladivostok",
        "offset": 10.0,
        "gmt": "GMT+10:00"
    },
    "Asia/Yakutsk": {
        "display_text": "(GMT+9:00) Asia/Yakutsk",
        "offset": 9.0,
        "gmt": "GMT+9:00"
    },
    "Asia/Yekaterinburg": {
        "display_text": "(GMT+5:00) Asia/Yekaterinburg",
        "offset": 5.0,
        "gmt": "GMT+5:00"
    },
    "Asia/Yerevan": {
        "display_text": "(GMT+4:00) Asia/Yerevan",
        "offset": 4.0,
        "gmt": "GMT+4:00"
    },
    "Atlantic/Azores": {
        "display_text": "(GMT-1:00) Atlantic/Azores",
        "offset": -1.0,
        "gmt": "GMT-1:00"
    },
    "Atlantic/Bermuda": {
        "display_text": "(GMT-4:00) Atlantic/Bermuda",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "Atlantic/Canary": {
        "display_text": "(GMT+0:00) Atlantic/Canary",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Atlantic/Cape_Verde": {
        "display_text": "(GMT-1:00) Atlantic/Cape_Verde",
        "offset": -1.0,
        "gmt": "GMT-1:00"
    },
    "Atlantic/Faroe": {
        "display_text": "(GMT+0:00) Atlantic/Faroe",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Atlantic/Madeira": {
        "display_text": "(GMT+0:00) Atlantic/Madeira",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Atlantic/Reykjavik": {
        "display_text": "(GMT+0:00) Atlantic/Reykjavik",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Atlantic/South_Georgia": {
        "display_text": "(GMT-2:00) Atlantic/South_Georgia",
        "offset": -2.0,
        "gmt": "GMT-2:00"
    },
    "Atlantic/Stanley": {
        "display_text": "(GMT-3:00) Atlantic/Stanley",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "Australia/Sydney": {
        "display_text": "(GMT+1:00) Australia/Sydney",
        "offset": 12.0,
        "gmt": "GMT+1:00"
    },
    "Australia/Adelaide": {
        "display_text": "(GMT+1:00) Australia/Adelaide",
        "offset": 11.5,
        "gmt": "GMT+1:00"
    },
    "Australia/Brisbane": {
        "display_text": "(GMT+10:00) Australia/Brisbane",
        "offset": 10.0,
        "gmt": "GMT+10:00"
    },
    "Australia/Broken_Hill": {
        "display_text": "(GMT+1:00) Australia/Broken_Hill",
        "offset": 11.5,
        "gmt": "GMT+1:00"
    },
    "Australia/Currie": {
        "display_text": "(GMT+1:00) Australia/Currie",
        "offset": 12.0,
        "gmt": "GMT+1:00"
    },
    "Australia/Darwin": {
        "display_text": "(GMT+9:30) Australia/Darwin",
        "offset": 9.5,
        "gmt": "GMT+9:30"
    },
    "Australia/Eucla": {
        "display_text": "(GMT+8:45) Australia/Eucla",
        "offset": 8.75,
        "gmt": "GMT+8:45"
    },
    "Australia/Hobart": {
        "display_text": "(GMT+1:00) Australia/Hobart",
        "offset": 12.0,
        "gmt": "GMT+1:00"
    },
    "Australia/Lord_Howe": {
        "display_text": "(GMT+0:30) Australia/Lord_Howe",
        "offset": 11.5,
        "gmt": "GMT+0:30"
    },
    "Australia/Lindeman": {
        "display_text": "(GMT+10:00) Australia/Lindeman",
        "offset": 10.0,
        "gmt": "GMT+10:00"
    },
    "Australia/Melbourne": {
        "display_text": "(GMT+1:00) Australia/Melbourne",
        "offset": 12.0,
        "gmt": "GMT+1:00"
    },
    "Australia/Perth": {
        "display_text": "(GMT+8:00) Australia/Perth",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "CET": {
        "display_text": "(GMT+1:00) CET",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Pacific/Easter": {
        "display_text": "(GMT+1:00) Pacific/Easter",
        "offset": -4.0,
        "gmt": "GMT+1:00"
    },
    "CST6CDT": {
        "display_text": "(GMT-6:00) CST6CDT",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "EET": {
        "display_text": "(GMT+2:00) EET",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Europe/Dublin": {
        "display_text": "(GMT+0:00) Europe/Dublin",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "EST5EDT": {
        "display_text": "(GMT-5:00) EST5EDT",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "Etc/GMT-0": {
        "display_text": "(GMT+0:00) Etc/GMT-0",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Etc/GMT-1": {
        "display_text": "(GMT+1:00) Etc/GMT-1",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Pacific/Port_Moresby": {
        "display_text": "(GMT+10:00) Pacific/Port_Moresby",
        "offset": 10.0,
        "gmt": "GMT+10:00"
    },
    "Etc/GMT-11": {
        "display_text": "(GMT+11:00) Etc/GMT-11",
        "offset": 11.0,
        "gmt": "GMT+11:00"
    },
    "Pacific/Tarawa": {
        "display_text": "(GMT+12:00) Pacific/Tarawa",
        "offset": 12.0,
        "gmt": "GMT+12:00"
    },
    "Etc/GMT-13": {
        "display_text": "(GMT+13:00) Etc/GMT-13",
        "offset": 13.0,
        "gmt": "GMT+13:00"
    },
    "Etc/GMT-14": {
        "display_text": "(GMT+14:00) Etc/GMT-14",
        "offset": 14.0,
        "gmt": "GMT+14:00"
    },
    "Etc/GMT-2": {
        "display_text": "(GMT+2:00) Etc/GMT-2",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Etc/GMT-3": {
        "display_text": "(GMT+3:00) Etc/GMT-3",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Etc/GMT-4": {
        "display_text": "(GMT+4:00) Etc/GMT-4",
        "offset": 4.0,
        "gmt": "GMT+4:00"
    },
    "Etc/GMT-5": {
        "display_text": "(GMT+5:00) Etc/GMT-5",
        "offset": 5.0,
        "gmt": "GMT+5:00"
    },
    "Etc/GMT-6": {
        "display_text": "(GMT+6:00) Etc/GMT-6",
        "offset": 6.0,
        "gmt": "GMT+6:00"
    },
    "Indian/Christmas": {
        "display_text": "(GMT+7:00) Indian/Christmas",
        "offset": 7.0,
        "gmt": "GMT+7:00"
    },
    "Etc/GMT-8": {
        "display_text": "(GMT+8:00) Etc/GMT-8",
        "offset": 8.0,
        "gmt": "GMT+8:00"
    },
    "Pacific/Palau": {
        "display_text": "(GMT+9:00) Pacific/Palau",
        "offset": 9.0,
        "gmt": "GMT+9:00"
    },
    "Etc/GMT+1": {
        "display_text": "(GMT-1:00) Etc/GMT+1",
        "offset": -1.0,
        "gmt": "GMT-1:00"
    },
    "Etc/GMT+10": {
        "display_text": "(GMT-10:00) Etc/GMT+10",
        "offset": -10.0,
        "gmt": "GMT-10:00"
    },
    "Etc/GMT+11": {
        "display_text": "(GMT-11:00) Etc/GMT+11",
        "offset": -11.0,
        "gmt": "GMT-11:00"
    },
    "Etc/GMT+12": {
        "display_text": "(GMT-12:00) Etc/GMT+12",
        "offset": -12.0,
        "gmt": "GMT-12:00"
    },
    "Etc/GMT+3": {
        "display_text": "(GMT-3:00) Etc/GMT+3",
        "offset": -3.0,
        "gmt": "GMT-3:00"
    },
    "Etc/GMT+4": {
        "display_text": "(GMT-4:00) Etc/GMT+4",
        "offset": -4.0,
        "gmt": "GMT-4:00"
    },
    "Etc/GMT+5": {
        "display_text": "(GMT-5:00) Etc/GMT+5",
        "offset": -5.0,
        "gmt": "GMT-5:00"
    },
    "Etc/GMT+6": {
        "display_text": "(GMT-6:00) Etc/GMT+6",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "Etc/GMT+7": {
        "display_text": "(GMT-7:00) Etc/GMT+7",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "Etc/GMT+8": {
        "display_text": "(GMT-8:00) Etc/GMT+8",
        "offset": -8.0,
        "gmt": "GMT-8:00"
    },
    "Etc/GMT+9": {
        "display_text": "(GMT-9:00) Etc/GMT+9",
        "offset": -9.0,
        "gmt": "GMT-9:00"
    },
    "Europe/Amsterdam": {
        "display_text": "(GMT+1:00) Europe/Amsterdam",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Andorra": {
        "display_text": "(GMT+1:00) Europe/Andorra",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Astrakhan": {
        "display_text": "(GMT+4:00) Europe/Astrakhan",
        "offset": 4.0,
        "gmt": "GMT+4:00"
    },
    "Europe/Athens": {
        "display_text": "(GMT+2:00) Europe/Athens",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Europe/London": {
        "display_text": "(GMT+0:00) Europe/London",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Europe/Belgrade": {
        "display_text": "(GMT+1:00) Europe/Belgrade",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Berlin": {
        "display_text": "(GMT+1:00) Europe/Berlin",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Prague": {
        "display_text": "(GMT+1:00) Europe/Prague",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Brussels": {
        "display_text": "(GMT+1:00) Europe/Brussels",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Bucharest": {
        "display_text": "(GMT+2:00) Europe/Bucharest",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Europe/Budapest": {
        "display_text": "(GMT+1:00) Europe/Budapest",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Zurich": {
        "display_text": "(GMT+1:00) Europe/Zurich",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Chisinau": {
        "display_text": "(GMT+2:00) Europe/Chisinau",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Europe/Copenhagen": {
        "display_text": "(GMT+1:00) Europe/Copenhagen",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Gibraltar": {
        "display_text": "(GMT+1:00) Europe/Gibraltar",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Helsinki": {
        "display_text": "(GMT+2:00) Europe/Helsinki",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Europe/Kaliningrad": {
        "display_text": "(GMT+2:00) Europe/Kaliningrad",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Europe/Kiev": {
        "display_text": "(GMT+2:00) Europe/Kiev",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Europe/Kirov": {
        "display_text": "(GMT+3:00) Europe/Kirov",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Europe/Lisbon": {
        "display_text": "(GMT+0:00) Europe/Lisbon",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    },
    "Europe/Luxembourg": {
        "display_text": "(GMT+1:00) Europe/Luxembourg",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Madrid": {
        "display_text": "(GMT+1:00) Europe/Madrid",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Malta": {
        "display_text": "(GMT+1:00) Europe/Malta",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Minsk": {
        "display_text": "(GMT+3:00) Europe/Minsk",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Europe/Monaco": {
        "display_text": "(GMT+1:00) Europe/Monaco",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Moscow": {
        "display_text": "(GMT+3:00) Europe/Moscow",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Europe/Paris": {
        "display_text": "(GMT+1:00) Europe/Paris",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Riga": {
        "display_text": "(GMT+2:00) Europe/Riga",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Europe/Rome": {
        "display_text": "(GMT+1:00) Europe/Rome",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Samara": {
        "display_text": "(GMT+4:00) Europe/Samara",
        "offset": 4.0,
        "gmt": "GMT+4:00"
    },
    "Europe/Saratov": {
        "display_text": "(GMT+4:00) Europe/Saratov",
        "offset": 4.0,
        "gmt": "GMT+4:00"
    },
    "Europe/Simferopol": {
        "display_text": "(GMT+3:00) Europe/Simferopol",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Europe/Sofia": {
        "display_text": "(GMT+2:00) Europe/Sofia",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Europe/Stockholm": {
        "display_text": "(GMT+1:00) Europe/Stockholm",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Tallinn": {
        "display_text": "(GMT+2:00) Europe/Tallinn",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Europe/Tirane": {
        "display_text": "(GMT+1:00) Europe/Tirane",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Ulyanovsk": {
        "display_text": "(GMT+4:00) Europe/Ulyanovsk",
        "offset": 4.0,
        "gmt": "GMT+4:00"
    },
    "Europe/Uzhgorod": {
        "display_text": "(GMT+2:00) Europe/Uzhgorod",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Europe/Vienna": {
        "display_text": "(GMT+1:00) Europe/Vienna",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Vilnius": {
        "display_text": "(GMT+2:00) Europe/Vilnius",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Europe/Volgograd": {
        "display_text": "(GMT+3:00) Europe/Volgograd",
        "offset": 3.0,
        "gmt": "GMT+3:00"
    },
    "Europe/Warsaw": {
        "display_text": "(GMT+1:00) Europe/Warsaw",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "Europe/Zaporozhye": {
        "display_text": "(GMT+2:00) Europe/Zaporozhye",
        "offset": 2.0,
        "gmt": "GMT+2:00"
    },
    "Indian/Chagos": {
        "display_text": "(GMT+6:00) Indian/Chagos",
        "offset": 6.0,
        "gmt": "GMT+6:00"
    },
    "Indian/Cocos": {
        "display_text": "(GMT+6:30) Indian/Cocos",
        "offset": 6.5,
        "gmt": "GMT+6:30"
    },
    "Indian/Kerguelen": {
        "display_text": "(GMT+5:00) Indian/Kerguelen",
        "offset": 5.0,
        "gmt": "GMT+5:00"
    },
    "Indian/Mahe": {
        "display_text": "(GMT+4:00) Indian/Mahe",
        "offset": 4.0,
        "gmt": "GMT+4:00"
    },
    "Indian/Maldives": {
        "display_text": "(GMT+5:00) Indian/Maldives",
        "offset": 5.0,
        "gmt": "GMT+5:00"
    },
    "Indian/Mauritius": {
        "display_text": "(GMT+4:00) Indian/Mauritius",
        "offset": 4.0,
        "gmt": "GMT+4:00"
    },
    "Indian/Reunion": {
        "display_text": "(GMT+4:00) Indian/Reunion",
        "offset": 4.0,
        "gmt": "GMT+4:00"
    },
    "Pacific/Kwajalein": {
        "display_text": "(GMT+12:00) Pacific/Kwajalein",
        "offset": 12.0,
        "gmt": "GMT+12:00"
    },
    "MET": {
        "display_text": "(GMT+1:00) MET",
        "offset": 1.0,
        "gmt": "GMT+1:00"
    },
    "MST7MDT": {
        "display_text": "(GMT-7:00) MST7MDT",
        "offset": -7.0,
        "gmt": "GMT-7:00"
    },
    "Pacific/Chatham": {
        "display_text": "(GMT+1:00) Pacific/Chatham",
        "offset": 14.75,
        "gmt": "GMT+1:00"
    },
    "Pacific/Apia": {
        "display_text": "(GMT+13:00) Pacific/Apia",
        "offset": 13.0,
        "gmt": "GMT+13:00"
    },
    "Pacific/Bougainville": {
        "display_text": "(GMT+11:00) Pacific/Bougainville",
        "offset": 11.0,
        "gmt": "GMT+11:00"
    },
    "Pacific/Chuuk": {
        "display_text": "(GMT+10:00) Pacific/Chuuk",
        "offset": 10.0,
        "gmt": "GMT+10:00"
    },
    "Pacific/Efate": {
        "display_text": "(GMT+11:00) Pacific/Efate",
        "offset": 11.0,
        "gmt": "GMT+11:00"
    },
    "Pacific/Enderbury": {
        "display_text": "(GMT+13:00) Pacific/Enderbury",
        "offset": 13.0,
        "gmt": "GMT+13:00"
    },
    "Pacific/Fakaofo": {
        "display_text": "(GMT+13:00) Pacific/Fakaofo",
        "offset": 13.0,
        "gmt": "GMT+13:00"
    },
    "Pacific/Fiji": {
        "display_text": "(GMT+12:00) Pacific/Fiji",
        "offset": 12.0,
        "gmt": "GMT+12:00"
    },
    "Pacific/Galapagos": {
        "display_text": "(GMT-6:00) Pacific/Galapagos",
        "offset": -6.0,
        "gmt": "GMT-6:00"
    },
    "Pacific/Gambier": {
        "display_text": "(GMT-9:00) Pacific/Gambier",
        "offset": -9.0,
        "gmt": "GMT-9:00"
    },
    "Pacific/Guadalcanal": {
        "display_text": "(GMT+11:00) Pacific/Guadalcanal",
        "offset": 11.0,
        "gmt": "GMT+11:00"
    },
    "Pacific/Guam": {
        "display_text": "(GMT+10:00) Pacific/Guam",
        "offset": 10.0,
        "gmt": "GMT+10:00"
    },
    "Pacific/Honolulu": {
        "display_text": "(GMT-10:00) Pacific/Honolulu",
        "offset": -10.0,
        "gmt": "GMT-10:00"
    },
    "Pacific/Kiritimati": {
        "display_text": "(GMT+14:00) Pacific/Kiritimati",
        "offset": 14.0,
        "gmt": "GMT+14:00"
    },
    "Pacific/Kosrae": {
        "display_text": "(GMT+11:00) Pacific/Kosrae",
        "offset": 11.0,
        "gmt": "GMT+11:00"
    },
    "Pacific/Majuro": {
        "display_text": "(GMT+12:00) Pacific/Majuro",
        "offset": 12.0,
        "gmt": "GMT+12:00"
    },
    "Pacific/Marquesas": {
        "display_text": "(GMT-9:30) Pacific/Marquesas",
        "offset": -9.5,
        "gmt": "GMT-9:30"
    },
    "Pacific/Pago_Pago": {
        "display_text": "(GMT-11:00) Pacific/Pago_Pago",
        "offset": -11.0,
        "gmt": "GMT-11:00"
    },
    "Pacific/Nauru": {
        "display_text": "(GMT+12:00) Pacific/Nauru",
        "offset": 12.0,
        "gmt": "GMT+12:00"
    },
    "Pacific/Niue": {
        "display_text": "(GMT-11:00) Pacific/Niue",
        "offset": -11.0,
        "gmt": "GMT-11:00"
    },
    "Pacific/Norfolk": {
        "display_text": "(GMT+1:00) Pacific/Norfolk",
        "offset": 13.0,
        "gmt": "GMT+1:00"
    },
    "Pacific/Noumea": {
        "display_text": "(GMT+11:00) Pacific/Noumea",
        "offset": 11.0,
        "gmt": "GMT+11:00"
    },
    "Pacific/Pitcairn": {
        "display_text": "(GMT-8:00) Pacific/Pitcairn",
        "offset": -8.0,
        "gmt": "GMT-8:00"
    },
    "Pacific/Pohnpei": {
        "display_text": "(GMT+11:00) Pacific/Pohnpei",
        "offset": 11.0,
        "gmt": "GMT+11:00"
    },
    "Pacific/Rarotonga": {
        "display_text": "(GMT-10:00) Pacific/Rarotonga",
        "offset": -10.0,
        "gmt": "GMT-10:00"
    },
    "Pacific/Tahiti": {
        "display_text": "(GMT-10:00) Pacific/Tahiti",
        "offset": -10.0,
        "gmt": "GMT-10:00"
    },
    "Pacific/Tongatapu": {
        "display_text": "(GMT+13:00) Pacific/Tongatapu",
        "offset": 13.0,
        "gmt": "GMT+13:00"
    },
    "PST8PDT": {
        "display_text": "(GMT-8:00) PST8PDT",
        "offset": -8.0,
        "gmt": "GMT-8:00"
    },
    "WET": {
        "display_text": "(GMT+0:00) WET",
        "offset": 0.0,
        "gmt": "GMT+0:00"
    }
};

export default TimeZones;