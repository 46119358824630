import * as React from 'react';
import { Box, Button } from "@mui/material"
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import styles from './TrackingCode.module.css';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLoader } from '../../Store/loader/loaderReducer';
import axios from 'axios';
import { toast } from 'react-toastify';

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);
const TrackingCode = ({ steps }) => {

  const [script, setScript] = useState('');

  const dispatch = useDispatch();

  const org_info = useSelector((state) => state?.org_info?.value);
  const org_id = org_info?.default_organizations?.Id;

  useEffect(() => {
    if (!script) fetchScript();
  }, []);

  const fetchScript = () => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_CONNECTOR, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: { org_id: org_id } })
      .then((res) => {
        setScript(res?.data?.script);
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };

  const handleNext = () => {
    steps(1);
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Box className={styles.tracking_code_head} >Install Centilio Connector tracking code on your website</Box>
      <ul>
        <li className={styles.tracking_code_list}>Put this snippet inside {"<head>"} or in this very beginning of the {"<body>"} tag.</li>
        <li className={styles.tracking_code_list}>It needs to be placed on every page on the website, even on pages without forms.</li>
      </ul>
      <Box className={styles.tracking_code_textarea} ><Textarea sx={{ resize: 'none', width: '500px' }} value={script} minRows={5} maxRows={6} placeholder='' /></Box>
      <Box className={styles.tracking_code_button_div}><Button className={styles.tracking_code_button} onClick={() => handleNext()} variant="contained" color="error">Next</Button></Box>
    </Box>
  )
}
export default TrackingCode;