import React from 'react'
import styles from "./DeleteModal.module.css";
import { Box, Button, Modal } from '@mui/material';

const DeleteModal = ({ open, btn_text, closeModal, handleDelete }) => {
    return (
        <Box>
            <Modal
                open={open}
                sx={{ "& .MuiBackdrop-root": { backgroundColor: "#13214440" } }}
                closeAfterTransition
            >
                <Box className={styles.file_wrap}>
                    <Box className={styles.delete_modal_text}>Are you sure?</Box>
                    <Box className={styles.delete_modal_buttons}>
                        <Button variant="outlined" className={styles.delete_container_cancel} onClick={closeModal}>Cancel</Button>
                        <Button variant="contained" className={styles.delete_modal} onClick={handleDelete}>{btn_text}</Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
};

export default DeleteModal;