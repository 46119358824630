import { Box, Button, IconButton, InputAdornment, InputLabel, Modal, TextField } from "@mui/material";
import styles from '../../../Pages/Integrations/Integration.module.css';
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";

const IntegrationModal = ({ modal, setModal, handleChange, apiKey, handleWixSubmit, formDetails, handleFormDetails, handleFormsSubmit, update }) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <Modal
            open={modal?.length ? true : false}
            sx={{ "& .MuiBackdrop-root": { backgroundColor: "#13214440" } }}
            closeAfterTransition
        >
            <Box className={styles.modal_wrapper}>
                <Box className={styles.main_wrap}>
                    <IconButton className={styles.close_btn} onClick={() => setModal(false)}>
                        <CloseIcon sx={{ "&:hover": { color: "#ed4c78" } }} />
                    </IconButton>
                    {modal === 'wix' && <Box sx={{ margin: '10px' }}>
                        <Box className={styles.file_post_container}>
                            <InputLabel className={styles.post_label}>Api Key</InputLabel>
                            <TextField
                                multiline
                                minRows={6}
                                maxRows={10}
                                className={styles.file_post_input}
                                onChange={(e) => handleChange(e.target.value, 'api_key')}
                                sx={{
                                    fieldset: { border: "1px solid #E6E7E9" },
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: 'var(--primary) !important',
                                        },
                                        '& fieldset.MuiOutlinedInput-notchedOutline': {
                                            border: "1px solid #E6E7E9"
                                        },
                                        fontSize: '14px',
                                        // paddingLeft: '2px',
                                    },
                                }}
                                value={apiKey?.api_key}
                                placeholder='Enter Api Key'
                            />
                        </Box>
                        <Box className={styles.file_post_container}>
                            <InputLabel className={styles.post_label}>Member Id</InputLabel>
                            <TextField
                                className={styles.file_post_input}
                                onChange={(e) => handleChange(e.target.value, 'member_id')}
                                sx={{
                                    fieldset: { border: "1px solid #E6E7E9" },
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: 'var(--primary) !important',
                                        },
                                        '& fieldset.MuiOutlinedInput-notchedOutline': {
                                            border: "1px solid #E6E7E9"
                                        },
                                        fontSize: '14px',
                                        paddingLeft: '2px',
                                        height: '41.92px'
                                    },
                                }}
                                value={apiKey?.member_id}
                                placeholder='Enter Member Id'
                            />
                        </Box>
                        <Box className={styles.file_post_container}>
                            <InputLabel className={styles.post_label}>Site Id</InputLabel>
                            <TextField
                                className={styles.file_post_input}
                                onChange={(e) => handleChange(e.target.value, 'site_id')}
                                sx={{
                                    fieldset: { border: "1px solid #E6E7E9" },
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: 'var(--primary) !important',
                                        },
                                        '& fieldset.MuiOutlinedInput-notchedOutline': {
                                            border: "1px solid #E6E7E9"
                                        },
                                        fontSize: '14px',
                                        paddingLeft: '2px',
                                        height: '41.92px'
                                    },
                                }}
                                value={apiKey?.site_id}
                                placeholder='Enter Site Id'
                            />
                        </Box>
                        <Box className={styles.btn_container}>
                            <Button variant='contained' className={styles.review_btn} onClick={() => handleWixSubmit()}>{update ? 'update' : 'Submit'}</Button>
                        </Box>
                    </Box>}
                    {(modal === 'word_press' || modal === 'custom_site') && <Box sx={{ margin: '10px' }}>
                        <Box className={styles.file_post_container}>
                            <InputLabel className={styles.post_label}>Application Name</InputLabel>
                            <TextField
                                className={styles.file_post_input}
                                onChange={(e) => handleFormDetails(e.target.value, 'app_name')}
                                sx={{
                                    fieldset: { border: "1px solid #E6E7E9" },
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: 'var(--primary) !important',
                                        },
                                        '& fieldset.MuiOutlinedInput-notchedOutline': {
                                            border: "1px solid #E6E7E9"
                                        },
                                        fontSize: '14px',
                                        paddingLeft: '2px',
                                        height: '41.92px'
                                    },
                                }}
                                value={formDetails?.app_name}
                                placeholder='Enter application name'
                            />
                        </Box>
                        <Box className={styles.file_post_container}>
                            <InputLabel className={styles.post_label}>Base Domain</InputLabel>
                            <TextField
                                className={styles.file_post_input}
                                onChange={(e) => handleFormDetails(e.target.value, 'base_domain')}
                                sx={{
                                    fieldset: { border: "1px solid #E6E7E9" },
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: 'var(--primary) !important',
                                        },
                                        '& fieldset.MuiOutlinedInput-notchedOutline': {
                                            border: "1px solid #E6E7E9"
                                        },
                                        fontSize: '14px',
                                        paddingLeft: '2px',
                                        height: '41.92px'
                                    },
                                }}
                                value={formDetails?.base_domain}
                                placeholder='https://example.com'
                            />
                        </Box>
                        <Box className={styles.file_post_container}>
                            <InputLabel className={styles.post_label}>Blog Path</InputLabel>
                            <TextField
                                className={styles.file_post_input}
                                onChange={(e) => handleFormDetails(e.target.value, 'blog_path')}
                                sx={{
                                    fieldset: { border: "1px solid #E6E7E9" },
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: 'var(--primary) !important',
                                        },
                                        '& fieldset.MuiOutlinedInput-notchedOutline': {
                                            border: "1px solid #E6E7E9"
                                        },
                                        fontSize: '14px',
                                        paddingLeft: '2px',
                                        height: '41.92px'
                                    },
                                }}
                                value={formDetails?.blog_path}
                                placeholder='Enter blog path'
                            />
                        </Box>
                        {modal === 'custom_site' && <Box className={styles.file_post_container}>
                            <InputLabel className={styles.post_label}>Server Location Path</InputLabel>
                            <TextField
                                className={styles.file_post_input}
                                onChange={(e) => handleFormDetails(e.target.value, 'target_location')}
                                sx={{
                                    fieldset: { border: "1px solid #E6E7E9" },
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: 'var(--primary) !important',
                                        },
                                        '& fieldset.MuiOutlinedInput-notchedOutline': {
                                            border: "1px solid #E6E7E9"
                                        },
                                        fontSize: '14px',
                                        paddingLeft: '2px',
                                        height: '41.92px'
                                    },
                                }}
                                value={formDetails?.target_location}
                                placeholder='Enter server location path'
                            />
                        </Box>}
                        <Box className={styles.file_post_container}>
                            <InputLabel className={styles.post_label}>Username</InputLabel>
                            <TextField
                                className={styles.file_post_input}
                                onChange={(e) => handleFormDetails(e.target.value, 'username')}
                                sx={{
                                    fieldset: { border: "1px solid #E6E7E9" },
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: 'var(--primary) !important',
                                        },
                                        '& fieldset.MuiOutlinedInput-notchedOutline': {
                                            border: "1px solid #E6E7E9"
                                        },
                                        fontSize: '14px',
                                        paddingLeft: '2px',
                                        height: '41.92px'
                                    },
                                }}
                                value={formDetails?.username}
                                placeholder='Enter username'
                            />
                        </Box>
                        <Box className={styles.file_post_container}>
                            <InputLabel className={styles.post_label}>Password</InputLabel>
                            <TextField
                                type={showPassword ? "text" : "password"}
                                className={styles.file_post_input}
                                onChange={(e) => handleFormDetails(e.target.value, 'password')}
                                sx={{
                                    fieldset: { border: "1px solid #E6E7E9" },
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: 'var(--primary) !important',
                                        },
                                        '& fieldset.MuiOutlinedInput-notchedOutline': {
                                            border: "1px solid #E6E7E9"
                                        },
                                        fontSize: '14px',
                                        paddingLeft: '2px',
                                        height: '41.92px'
                                    },
                                }}
                                value={formDetails?.password}
                                placeholder='Enter password'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowPassword((prev) => !prev)}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box className={styles.btn_container}>
                            <Button variant='contained' className={styles.review_btn} onClick={() => handleFormsSubmit()}>{update ? 'update' : 'Submit'}</Button>
                        </Box>
                    </Box>}
                </Box>
            </Box>
        </Modal>
    )
};

export default IntegrationModal;