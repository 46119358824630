import { Box, Button } from "@mui/material";
import React, { useRef, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import styles from './Upload.module.css';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { OrgNavigation } from "../Utils/handleOrgNavigation";
import { toggleLoader } from "../Store/loader/loaderReducer";
import axios from "axios";

const Upload = () => {
    const [isProgress, setIsProgress] = useState(false);

    const fileInput = useRef(null);
    const dispatch = useDispatch();
    const navPath = OrgNavigation();
    const org_info = useSelector((state) => state?.org_info?.value);


    const handleUpload = (e) => {


        const file = e.target.files[0];
        if (!(file['type'].includes('text/csv') || file['type'].includes('xlsx') || file['type'].includes('xls') || file['name'].includes('xls') || file['name'].includes('xlsx'))) {
            toast.error('Invalid file format. Please upload a valid file in the appropriate format (e.g. .text/csv, .xlsx, .xls ).');
            return;
        };

        setIsProgress(true);
        let form = {
            file: file,
            org_id: org_info?.default_organizations?.Id,
        };

        const formData = new FormData();

        Object.keys(form).forEach((key) => {
            formData.append(key, form[key]);
        });


        dispatch(toggleLoader(true));
        axios.post(process.env.REACT_APP_BOTCONTENT, formData, { withCredentials: process.env.REACT_APP_CREDENTIALS, responseType: 'arraybuffer' })
            .then((res) => {
                setIsProgress(false);
                dispatch(toggleLoader(false));
                navPath('/post/drafts');
            })
            .catch((err) => {
                setIsProgress(false);
                dispatch(toggleLoader(false));
                if (err?.response?.data?.error?.message === "file_data_invalid") {
                    toast.error('File data is invalid');
                } else if (err?.response?.data?.error?.message === "file_headres_invalid") {
                    toast.error(err?.response?.data?.error?.details);
                } else {
                    if (err.response.status === 400) {
                        toast.error(err.response.data.error.details);
                    }
                    else if (err.response.status === 422) {
                        const blobFile = new Blob([err.request.response], { type: "application/pdf" });
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blobFile);
                        link.download = 'seo_bot_sample.xlsx';
                        link.click();
                        toast.error('Please check the file data or format and try again.');
                    }
                    else toast.error(err.message);
                };
            })
    };

    const onDownload = () => {
        const url = 'https://us-cdn1.centilio.com/static_files/seo-bot-sample.xlsx'; // Replace with your file URL
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'seo-bot-sample.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <Box className={styles.wrapper}>
            <Box className={styles.header_title}>
                <Box className={styles.title}>
                    <Box className={styles.header_text}>Upload</Box>
                </Box>

            </Box>
            <Box className={styles.btn_samble_box}>
                <img
                    src={process.env.REACT_APP_IMAGE_URL + 'upload/sample_icon.png'}
                    className={styles.sample_icon}
                    alt='menu-item'
                />
                <Box className={styles.text_sample}>Download the sample<span className={styles.file_download} onClick={onDownload}>file</span></Box>
            </Box>
            <Box className={styles.upsec_area}>
                <Box className={styles.browse_inn_part}>
                    <input
                        ref={fileInput}
                        type='file'
                        id='upload-doc'
                        style={{ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: "100%", zIndex: 1 }}
                        onChange={(e) => handleUpload(e)}
                    />

                    {!isProgress ? (
                        <Box className={styles.before_upload}>
                            <Box className={styles.supported_formats}>
                                <Box className={styles.formats}>Supported formats: <Box className={styles.formats_group}>Text/csv, Xlsx, Xls</Box></Box>
                            </Box>
                            <Box>
                                <img
                                    src={process.env.REACT_APP_IMAGE_URL + 'upload/dropbox.svg'}
                                    className={styles.box_img}
                                    alt='menu-item'
                                />
                                <Box className={styles.upload_file_text}>Drag and Drop your file here</Box>
                                <span className={styles.or}>or</span>
                            </Box>
                            <Button
                                variant='contained'
                                onClick={() => fileInput.current.click()}
                                className={styles.btn}
                                component="label"
                                onChange={handleUpload}
                            >
                                <span>Choose file</span>
                            </Button>

                        </Box>
                    ) : (
                        <Box className={styles.upload_inprogress}>
                            <Box className={styles.loader_wrap}>
                                <CircularProgress />
                            </Box>
                            <p className={styles.up_text}>Uploading...</p>
                            <p className={styles.up_hint}>Wait until your file is uploaded.</p>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
};

export default Upload;