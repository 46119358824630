import axios from "axios";

export const getChannelConnect = (data, resp, error) => {
    let options = { org_id: data.org_id, channel_type: data.channel_type };

    switch (data.channel_type) {
        case 9:
            axios.get(process.env.REACT_APP_WIX_CONNECT, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: { org_id: data.org_id } })
                .then((res) => {
                    resp(res);
                })
                .catch((err) => {
                    error(err);
                });
            break;
        case 3:
            axios.get(process.env.REACT_APP_ZOHO_CRM, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: options })
                .then((res) => {
                    resp(res);
                })
                .catch((err) => {
                    error(err);
                });
            break;
        case 10:
            axios.get(process.env.REACT_APP_WORDPRESS_CONNECT, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: { org_id: data.org_id } })
                .then((res) => {
                    resp(res);
                })
                .catch((err) => {
                    error(err);
                });
            break;
        case 4:
        case 5:
        case 6:
            axios.get(process.env.REACT_APP_SOCIAL, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: options })
                .then((res) => {
                    resp(res);
                })
                .catch((err) => {
                    error(err);
                });
            break;
        default:
            break;
    }
};

export const getChannelFiles = (data, resp, error) => {
    let options = { org_id: data.org_id, channel_type: data.channel_type };
    axios.get(process.env.REACT_APP_CRM_CHANNEL, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: options })
        .then((res) => {
            resp(res);
        })
        .catch((err) => {
            error(err);
        });
};