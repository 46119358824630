import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import TrackingCode from './TrackingCode';
import Authorize from './Authorize';
import { useState } from 'react';
import styles from './Connector.module.css';

const steps = ['Install Centilio Connector tracking code on your website', 'Authorize Centilio Connector app in Zoho CRM'];

const Connector = () => {

    const [activeStep, setActiveStep] = useState(0);

    const handleactiveStep = (value) => {
        setActiveStep(value)
    }

    return (
        <Box className={styles.integrations_head}>
            <Box className={styles.integrations_stepper_top} >
                <Stepper
                    activeStep={activeStep} orientation="vertical"
                    sx={{
                        alignItems: 'center',
                        '& .MuiStepConnector-root': {
                            margin: 'unset'
                        },
                        '& .MuiStepLabel-iconContainer': {
                            paddingRight: 'unset',
                            paddingBottom: '15px'
                        },
                        padding: '25px 0px 0px 35px',
                    }}>
                    {steps.map((label, index) => {
                        return (
                            <Step key={label} >
                                <StepLabel sx={{
                                    "& .MuiStepLabel-label": {
                                        textAlign: 'center',
                                    },
                                    "& .MuiStepConnector-line": {
                                        background: 'red'
                                    },
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    {label}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>
            <Box>
                <Box className={styles.integrations_stepper_top_head}>
                    <Box className={styles.integrations_stepper_heading} >Centilio Connector Installation Guide for Zoho CRM</Box>
                    <Box className={styles.integrations_taps_box} >
                        {activeStep === 0 ?
                            <Box className={styles.integrations_trackingcode_box} >
                                <TrackingCode steps={handleactiveStep} />
                            </Box>
                            :
                            <Box className={styles.integrations_authorize_box}>
                                <Authorize steps={handleactiveStep} />
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
export default Connector