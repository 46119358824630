import React, { useEffect, useState } from 'react';
import styles from './NavBar.module.css';
import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPathFromLocalStroage } from '../../utils';

const NavBar = ({ expand, isFetched }) => {
    const [active, setActive] = useState('Post');
    const [toggle, setToggle] = useState(true);
    const [loader, setLoader] = useState(false);
    const pathLocal = getPathFromLocalStroage();

    const location = useLocation();
    const org_info = useSelector((state) => state?.org_info?.value);
    const org_id = org_info?.default_organizations?.Id;

    useEffect(() => {
        let menu_bar = org_info && JSON.parse(org_info?.properties)[0]?.MENU_BAR;
        if (expand === null) {
            setToggle(menu_bar && !JSON.parse(menu_bar));
        } else {
            setToggle(!expand);
        }
    }, [expand, org_info]);

    const nav = [
        { name: 'Post', value: `c${org_id}/${pathLocal.path}`, img: 'House.svg', activeImg: 'home-active.svg' },
        { name: 'Calendar', value: `c${org_id}/calendar`, img: 'schedule-date.svg', activeImg: 'schedule-date-active.svg' },
        { name: 'Lead Tracker', value: `c${org_id}/Connector`, img: 'lead-tracker.svg', activeImg: 'lead-tracker-active.svg' },
        { name: 'Integration', value: `c${org_id}/Integration`, img: 'integration.svg', activeImg: 'integration-active.svg' },
        { name: 'Settings', value: `c${org_id}/settings`, img: 'Settings.svg', activeImg: 'settings-active.svg' },
    ];

    useEffect(() => {
        setTimeout(() => {
            setLoader(true);
        }, 2000);
    }, []);

    useEffect(() => {
        let reload = window.performance.getEntriesByType('navigation').map((nav) => nav.type);
        let navPath = nav?.find((v, i) => `/${v.value}` === location.pathname);

        if (reload[0] === 'reload' && navPath) {
            setActive(navPath?.name);
        } else if (isFetched && navPath) {
            setActive(navPath?.name);
        }
    }, [location.pathname]);

    return (
        <Box>
            {isFetched && <Box className={`${styles.nav_wrap} ${toggle && styles.nav_expand}`}>
                <NavLink to={`c${org_id}/${pathLocal.path}`} className={styles.centilio_title} style={{ marginLeft: toggle ? '5px' : '20px' }}>
                    <img
                        src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/Centilio.png'}
                        className={styles.welcome_img}
                        alt='welcome-img'
                    />

                    {!toggle && (
                        <Typography className={styles.centilio_sign}>
                            Centilio SEO Bot
                        </Typography>
                    )}
                </NavLink>
                <Box className={styles.nav_body}>
                    {
                        nav?.map((item, i) => {
                            return (
                                <Box key={item.name} sx={{ padding: '5px 10px' }}>
                                    {loader ?
                                        <NavLink
                                            to={item.value}
                                            className={`${styles.nav_item} ${active === item.name && !toggle && styles.active_nav}`}
                                            style={
                                                {
                                                    ...toggle
                                                        ? {
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            padding: '5px 21px',
                                                            translate: '-3px',
                                                            borderRadius: '3px'
                                                        }
                                                        : {}, ...active === item.name ? { backgroundColor: "rgba(189,197,209,.2)", color: 'var(--sidebar-icon-color)' } : { color: 'var(--table-font-color)' }
                                                }
                                            }
                                            key={item.name}
                                            onClick={() => setActive(item?.name)}
                                        >
                                            {active === item.name ? (
                                                <Tooltip title={toggle ? item.name : null} placement="right">
                                                    <img
                                                        className={styles.nav_name}
                                                        style={{ ...toggle ? { margin: 'unset' } : {}, }}
                                                        alt={item.name}
                                                        src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/' + item.activeImg}
                                                    />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title={toggle ? item.name : null} placement="right">
                                                    <img
                                                        className={styles.nav_name}
                                                        style={toggle ? { margin: 'unset' } : {}}
                                                        alt={item.name}
                                                        src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/' + item.img}
                                                    />
                                                </Tooltip>
                                            )}
                                            {!toggle && <p style={{ fontSize: '14px', }}>{item.name}</p>}
                                        </NavLink> : <Box className={styles.skeleton_wrapper}><Skeleton sx={{ bgcolor: 'rgba(189,197,209,.2)' }} key={item.name} variant="rectangular" width="100%" height="40px" /></Box>}
                                </Box>
                            )
                        })}
                </Box>
            </Box>}
        </Box>
    );
};

export default NavBar;