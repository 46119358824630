import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Drawer, IconButton, Modal, TextField, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { toggleLoader } from "../../Store/loader/loaderReducer";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import JoditEditor from "jodit-react";
import { getPathFromLocalStroage } from "../../utils";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import './Editor.css';
import styles from "./Editor.module.css";

import moment from "moment";
import axios from "axios";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SyncIcon from '@mui/icons-material/Sync';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import ScheduleModal from "../Modal/ScheduleModal/ScheduleModal";
import ConfirmationModal from "../Modal/ConfirmationModal/ConfirmationModal";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReviewsIcon from '@mui/icons-material/Reviews';
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";

export const allowedFileTypes = ['jpg', 'jpeg', 'png', 'gif', 'jfif', 'pjpeg', 'pjp', 'svg', 'bmp', 'tif', 'tiff', 'webp', 'ico', 'jp2', 'heic', 'heif', 'wbmp'];

const Editor = () => {
  const editorRef1 = useRef(null);
  const joditWrapperRef = useRef(null);
  const textAreaRef = useRef(null);
  const { id } = useParams();

  const toolbarContainerRef = useRef(null);
  const cropperRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const scheduleValues = location?.state?.item;
  const org_info = useSelector((state) => state?.org_info?.value);
  const org_id = org_info?.default_organizations?.Id;

  const dispatch = useDispatch();
  const [tabvalue, setTabvalue] = useState(null);
  const [schedule, setSchedule] = useState({ title: "", keyword: [], date: "", content: "", id: "", scheduled_time: "" });
  const [open, setOpen] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [type, setType] = useState('');
  const [drawer, setDrawer] = useState(false);
  const [excerpt, setExcerpt] = useState('');
  const [image, setImage] = useState(null);
  const [toggleImage, setToggleImage] = useState(false);
  const [fileId, setFileId] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const redioBtn = [0, 1];
  const maxChars = 200;
  const pathLocal = getPathFromLocalStroage();

  useEffect(() => {
    handleDrafts()
  }, [location])

  const handleDrafts = () => {
    let data = {
      post_id: id,
      org_id: org_id,
    };

    dispatch(toggleLoader(true));
    axios
      .get(process.env.REACT_APP_POST, {
        params: data,
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        const response = res.data.data[0];
        setSchedule({ title: response.Title, keyword: response.Keywords, date: response.Scheduled_Time, content: response?.Content, id: response.Id, scheduled_time: response?.Scheduled_Time, status: response?.Status })
        setTabvalue(response.Status);
        setFileId(response?.File_Id);
        setExcerpt(response?.Excerpt);
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
        navigate(`/c${org_id}${pathLocal.path}`);
        dispatch(toggleLoader(false));
      });
  };

  useEffect(() => {
    const handleResize = () => {
      const titleTextarea = document.querySelector('.jodit-toolbar__box');
      const compStyles = window.getComputedStyle(titleTextarea);
      document.documentElement.style.setProperty('--custom_textarea_margin_top', compStyles.height)

    }
    handleResize();
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (toolbarContainerRef.current) {
      config.toolbarContainer = toolbarContainerRef.current;
    }
  }, []);

  useEffect(() => {
    if (type && type !== 'Trash') {
      postOptions();
    }
  }, [type]);

  function getTextContentHeight(textarea) {
    const originalHeight = textarea.style.height;
    textarea.style.height = 'auto';
    textarea.scrollTop = 10000;
    const contentHeight = textarea.scrollHeight;
    textarea.scrollTop = 0;
    return contentHeight;
  }



  const handleChange = (event) => {


    const inputText = event.target.value;
    const isDeleting = inputText.length < schedule.title.length
    const contentHeight = getTextContentHeight(textAreaRef.current) + (isDeleting ? 0 : 0);
    textAreaRef.current.style.height = contentHeight + 'px'
    document.documentElement.style.setProperty('--toolbar__box_margin_bottom', contentHeight + 'px')


    if (inputText.length <= maxChars) {
      setSchedule({ ...schedule, title: inputText })

    } else {
      // Optionally, show a message to the user
      // alert(`You can only enter up to ${maxChars} words.`);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };



  const config = useMemo(
    () => ({
      // "enter": "BR",
      "readonly": false,
      'enableDragAndDropFileToEditor': true,
      "showCharsCounter": false,
      "defaultActionOnPaste": "insert_clear_html",
      "showWordsCounter": false,
      "showXPathInStatusbar": false,
      'disablePlugins': 'add-new-line',
      'autoresize': false,
      "allowResizeY": false,
      "disablePlugins": "placeholder",


      uploader: {
        insertImageAsBase64URI: true,
        imagesExtensions: ['jpg', 'png', 'jpeg', 'gif', 'svg', 'webp'],
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },

      },
      buttons: ['bold', 'italic', 'underline', 'image', 'strikethrough', 'eraser', 'ul', 'ol', 'font', 'fontsize', 'paragraph', 'lineHeight', 'indent', 'outdent', 'left', 'undo', 'redo']
    }), []);

  const configtwo = useMemo(
    () => ({
      // "enter": "BR",
      "readonly": true,
      'enableDragAndDropFileToEditor': true,
      "showCharsCounter": false,
      "defaultActionOnPaste": "insert_clear_html",
      "showWordsCounter": false,
      "showXPathInStatusbar": false,
      'disablePlugins': 'add-new-line',
      'autoresize': false,
      "allowResizeY": false,
      "disablePlugins": "placeholder",


      uploader: {
        insertImageAsBase64URI: true,
        imagesExtensions: ['jpg', 'png', 'jpeg', 'gif', 'svg', 'webp'],
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },

      },
      buttons: ['bold', 'italic', 'underline', 'image', 'strikethrough', 'eraser', 'ul', 'ol', 'font', 'fontsize', 'paragraph', 'lineHeight', 'indent', 'outdent', 'left', 'undo', 'redo']
    }), []);

  const insertBase64Image = () => {
    const base64Image = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...'; // Add your full base64 string here
    // console.log(editorRef1.current.selection, 'editorRef1.current');
    // Ensure the editor is initialized
    if (editorRef1.current && editorRef1.current.editor) {
      editorRef1.current.editor.selection.insertHTML(`<img src="${base64Image}" alt="Base64 Image" />`);
    } else {
      console.error('Editor not initialized');
    }
  };

  const handleSaveDraft = () => {

    let data = {
      title: schedule.title,
      keywords: schedule.keyword,
      scheduled_date: moment(schedule?.date, "DD/MM/YYYY hh:mm A").format("DD-MMM-YYYY hh:mm"),
      content: schedule.content,
      org_id: org_info?.default_organizations?.Id,
      id: schedule.id,
      excerpt: excerpt
    };
    if (tabvalue === 2) {
      delete data.scheduled_date;
    }
    dispatch(toggleLoader(true));
    axios
      .put(process.env.REACT_APP_POST, data, {
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(toggleLoader(false));
        toast.success('Saved');
        // navigate(`/c${org_id}${pathLocal.path}`);
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };

  const handleApprove = () => {

    let data = {
      title: schedule.title,
      org_id: org_info?.default_organizations?.Id,
      post_id: schedule.id,
      content: schedule.content,
      excerpt: excerpt
    };

    dispatch(toggleLoader(true));
    axios
      .post(process.env.REACT_APP_PUBLISH, data, {
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(toggleLoader(false));
        localStorage.setItem(
          "path",
          JSON.stringify({ path: "/post/published", value: 2 })
        );
        navigate(`/c${org_id}${pathLocal.path}`);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.details);
        dispatch(toggleLoader(false));
      });
  };

  const postOptions = () => {
    let data = {
      ids: [schedule.id],
      limit: 100,
      cursor: 1,
      org_id: org_info?.default_organizations?.Id,
    };
    dispatch(toggleLoader(true));
    const API =
      type === "Trash"
        ? process.env.REACT_APP_POST
        : type === "Delete"
          ? process.env.REACT_APP_TRASH :
          type === "Revert" ?
            process.env.REACT_APP_REVERTDRAFT :
            process.env.REACT_APP_RESTORE
    if (type === "Restore") {
      axios
        .post(API, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
          dispatch(toggleLoader(false));
          localStorage.setItem(
            "path",
            JSON.stringify({ path: "/post/drafts", value: 0 })
          );
          navigate(`/c${org_id}${pathLocal.path}`);
          toast.success(
            type === "Restore" && "Post restored and moved to drafts."
          );
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
          navigate(`/c${org_id}${pathLocal.path}`);
        });
    }
    else if (type === "Revert") {
      axios
        .post(API, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
          dispatch(toggleLoader(false));
          localStorage.setItem(
            "path",
            JSON.stringify({ path: "/post/drafts", value: 0 })
          );
          navigate(`/c${org_id}${pathLocal.path}`);
          // toast.success(
          //   postType === "Restore" && "Post restored and moved to drafts."
          // );
        })

        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
        });
    }


    else {
      axios
        .delete(API, {
          data: data,
          withCredentials: process.env.REACT_APP_CREDENTIALS,
        })
        .then((res) => {
          dispatch(toggleLoader(false));
          setConfirmation(false)
          navigate(`/c${org_id}${pathLocal.path}`);
          // {
          //   type === "Trash" && localStorage.setItem(
          //     "path",
          //     JSON.stringify({ path: "/post/trash", value: 5 })
          //   );
          // }
          toast.success(
            type === "Trash" ? "Post moved to trash." : "Post deleted."
          );
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
          navigate(`/c${org_id}${pathLocal.path}`);
          setConfirmation(false)
        });
    }

  };

  const handleDraft = () => {
    let data = {
      id: schedule.id,
      title: schedule.title,
      keywords: schedule.keyword,
      scheduled_date: moment(schedule?.date, "DD/MM/YYYY hh:mm A").format("DD-MMM-YYYY hh:mm"),
      content: schedule.content,
      org_id: org_info?.default_organizations?.Id,
      excerpt: excerpt
    };
    if (data.scheduled_date === 'Invalid date') {
      delete data.scheduled_date;
    }

    dispatch(toggleLoader(true));
    axios
      .post(process.env.REACT_APP_POST, data, {
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(toggleLoader(false));
        navigate(`/c${org_id}${pathLocal.path}`);
      })
      .catch((err) => {
        toast.error(err.message);
        navigate(`/c${org_id}${pathLocal.path}`);
        dispatch(toggleLoader(false));
      });
  };

  const handleSentForReview = (item) => {

    let data = {
      org_id: org_info?.default_organizations?.Id,
      post_id: schedule.id,
    };

    dispatch(toggleLoader(true));
    axios
      .post(process.env.REACT_APP_SEND_FOR_REVIEW, data, {
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(toggleLoader(false));
        navigate(`/c${org_id}${pathLocal.path}`);
        localStorage.setItem(
          "path",
          JSON.stringify({ path: "/post/pending-review", value: 4 })
        );
        // changeTabValue(3);
      })
      .catch((err) => {
        // console.log('err', err.response.data.error.details);
        toast.error(err?.response?.data?.error?.details);
        dispatch(toggleLoader(false));
      });
  };


  const getTooltipTitle = (item) => {
    return (
      <Box className={styles.table_moreinfo_popper}>

        {/* <Button
          startIcon={<SyncIcon />}
          variant="text"
          onClick={() => handleSaveDraft()}
        >
          Save as draft
        </Button> */}
        {tabvalue !== 2 && <Button
          startIcon={<ScheduleSendIcon />}
          variant="text"
          onClick={() => setOpen(true)}
        >
          Schedule post
        </Button>}
        {tabvalue === 2 && <Button
          startIcon={<SyncIcon />}
          variant="text"
          onClick={() => { setType("Revert") }}
        >
          Revert to Draft
        </Button>}
        <Button
          startIcon={<ContentCopyIcon />}
          variant="text"
          onClick={() => handleDraft()}
        >
          Duplicate draft
        </Button>
        <Button
          startIcon={<DeleteOutlineIcon />}
          className={styles.table_trash_btns_delete}
          variant="text"
          onClick={() => { setType("Trash"); setConfirmation(true) }}
        >
          Move to trash
        </Button>
        {redioBtn.includes(tabvalue) && <Button
          startIcon={<ReviewsIcon />}
          // className={styles.table_trash_btns_delete}
          variant="text"
          onClick={() => handleSentForReview()}
        >
          Send for review
        </Button>}
        {/* <Button
          startIcon={<DeleteOutlineIcon />}
          className={styles.table_trash_btns_delete}
          variant="text"
          onClick={() => {setType("Delete");setConfirmation(true)}}
        >
          Delete
        </Button> */}
      </Box>
    );
  };


  const handleSchedule = (date) => {

    let data = {
      org_id: org_info?.default_organizations?.Id,
      post_id: schedule.id,
      scheduled_date: moment(date, "DD-MMM-YYYY hh:mm a").format("DD-MMM-YYYY HH:mm"),
    };



    dispatch(toggleLoader(true));
    if (tabvalue === 0) {
      axios.post(process.env.REACT_APP_SCHEDULE, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
          dispatch(toggleLoader(false));
          localStorage.setItem(
            "path",
            JSON.stringify({ path: "/post/scheduled", value: 1 })
          );
          navigate(`/c${org_id}${pathLocal.path}`);
          setOpen(false);
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
          setOpen(false)
        });
    } else {
      axios.put(process.env.REACT_APP_SCHEDULE, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
          dispatch(toggleLoader(false));
          localStorage.setItem(
            "path",
            JSON.stringify({ path: "/post/scheduled", value: 1 })
          );
          navigate(`/c${org_id}${pathLocal.path}`);
          setOpen(false);
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
          setOpen(false)
        });
    }
  };


  const handleRejectandDraft = () => {
    let data = {
      org_id: org_info?.default_organizations?.Id,
      post_id: schedule.id,
    };

    dispatch(toggleLoader(true));
    axios
      .post(process.env.REACT_APP_REJECT, data, {
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(toggleLoader(false));
        toast.success('Successfully Rejected');
        localStorage.setItem(
          "path",
          JSON.stringify({ path: "/post/drafts", value: 0 })
        );
        navigate(`/c${org_id}${pathLocal.path}`);

      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };

  const handleCancelSchedule = () => {
    const data = {
      org_id: org_info?.default_organizations?.Id,
      post_id: schedule.id,
    }
    axios
      .delete(process.env.REACT_APP_SCHEDULE, {
        data: data,
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(toggleLoader(false));
        toast.success('Success');
        navigate(`/c${org_id}${pathLocal.path}`);

      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };

  const handleEditorChange = (newContent) => {
    setSchedule({ ...schedule, content: newContent });
  };

  const handleUpload = (e) => {
    const url = URL.createObjectURL(e);
    const fileExtension = e?.name?.split(".")?.at(-1);
    if (!allowedFileTypes.includes(fileExtension)) {
      toast.error('Only image format is accepted');
    } else {
      setImage(url);
      setToggleImage(true);
    }
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  }));

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleDelete = () => {
    const data = {
      org_id: org_info?.default_organizations?.Id,
      file_id: fileId,
      post_id: id
    }
    dispatch(toggleLoader(true));
    axios.delete(process.env.REACT_APP_FEATURED_IMAGE, { withCredentials: process.env.REACT_APP_CREDENTIALS, data: data })
      .then((res) => {
        setToggleImage(false);
        setImage(null);
        setFileId(null);
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        dispatch(toggleLoader(false));
        toast.error(err.message);
      });
  };

  const DrawerList = (
    <Box sx={{ width: 300 }}>
      <DrawerHeader>
        <Box className={styles.post_setting}>Post settings</Box>
        <IconButton onClick={() => setDrawer(false)}>
          <CloseIcon fontSize="small" sx={{ color: "var(--primary)" }} />
        </IconButton>
      </DrawerHeader>
      <Box sx={{ padding: '0px 19px' }}>
        <Box className={styles.featured_img_text}>Featured image<Box className={styles.info}>i</Box></Box>
        <Box className={styles.upload_wrap}>
          {fileId ?
            <Box className={styles.update_container} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
              <Box className={styles.featured_wrap} sx={{ opacity: isHovered && '0.1 !important' }}>
                <img src={`${process.env.REACT_APP_ATTACHMENT}?file_id=${fileId}`} className={styles.feature_image} alt='featured' />
              </Box>
              {isHovered && (<Box className={styles.update_wrap}>
                <Box component="label" tabIndex={-1} className={styles.update_circle_icon}>
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(event) => handleUpload(event.target.files[0])}
                    multiple
                  />
                  <img src={`${process.env.REACT_APP_IMAGE_URL}PostSvg/update-img.svg`} className={styles.update_image} alt='update' />
                </Box>
                <Box className={styles.update_icon} onClick={handleDelete}>
                  <DeleteIcon style={{ color: '#686583' }} fontSize="small" />
                </Box>
              </Box>)}
            </Box> :
            <Button component="label" variant="outlined" tabIndex={-1} className={styles.upload_btn}>
              +
              <VisuallyHiddenInput
                type="file"
                onChange={(event) => handleUpload(event.target.files[0])}
                multiple
              />
            </Button>}
        </Box>
        <Box className={styles.featured_img_text}>Excerpt<Box className={styles.info}>i</Box></Box>
        <textarea
          value={excerpt}
          className={styles.excerpt_field}
          onChange={(e) => setExcerpt(e.target.value)}
          placeholder="Write a brief excerpt here..."
          rows="3"
          cols="3"
        />
      </Box>
    </Box>
  );

  const handleSignature = () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      let data = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
      let blob = dataURLtoBlob(data);
      let file = new File([blob], 'featured.jpeg');
      let form = { file: file, org_id: org_info?.default_organizations?.Id, post_id: id }
      const formData = new FormData();

      Object.keys(form).forEach((key) => {
        formData.append(key, form[key]);
      });
      dispatch(toggleLoader(true));
      axios.post(process.env.REACT_APP_FEATURED_IMAGE, formData, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
          setFileId(res?.data?.response?.fileId);
          setToggleImage(false);
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          dispatch(toggleLoader(false));
          toast.error(err.message);
        });
    }
  };

  const dataURLtoBlob = (dataURL) => {
    let arr = dataURL.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let b64 = atob(arr[1]);
    let n = b64.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = b64.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  return (
    <Box className='editor'>
      <Box className={styles.main_div}>
        {tabvalue !== null && tabvalue !== 4 ?
          <Box className={styles.topbar_back_btn} >
            <Box sx={{ paddingTop: '10px' }}>
              <Button variant="outlined" sx={{ textTransform: 'capitalize' }} startIcon={<ArrowBackIcon />} onClick={() => navigate(`/c${org_id}${pathLocal.path}`)}> Back </Button>
            </Box>
            <Box className={styles.topbar_btn}>
              {schedule?.scheduled_time && schedule?.status !== 3 && schedule?.status !== 4 && <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>{schedule?.status === 2 ? `Published on ${schedule?.scheduled_time}` : `Scheduled for ${schedule?.scheduled_time}`}</Box>}
              <Button onClick={handleSaveDraft} variant="outlined" disabled={schedule.title.length <= 0}> Save </Button>
              <Button onClick={handleApprove} variant="contained" disabled={schedule.title.length <= 0}>{schedule.date ? tabvalue !== 0 ? 'Save and Publish' : 'Save and Schedule' : 'Save and Publish'} </Button>
              <Box className={styles.settings_container}>
                <img
                  src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/Settings.svg'}
                  className={styles.setting_icon}
                  alt='setting-img'
                  onClick={() => setDrawer(true)}
                />
              </Box>
              <Box className={styles.table_user_more_icon}>
                <Tooltip
                  title={getTooltipTitle()}
                  componentsProps={{
                    tooltip: { sx: { bgcolor: "white" } },
                  }}
                  placement="bottom"
                  arrow
                >
                  <MoreHorizIcon
                    className={styles.table_user_more_btn}
                    sx={{
                      border: "1px solid rgba(231, 234, 243, 0.70)",
                      borderRadius: "50%",
                      fill: "#377DFF",
                    }}
                  />
                </Tooltip>
              </Box>
            </Box>
          </Box> : tabvalue !== null && <Box className={styles.topbar_back_btn} >
            <Box sx={{ paddingTop: '10px' }}>
              <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={() => navigate(`/c${org_id}${pathLocal.path}`)}> Back </Button>
            </Box>

            <Box className={styles.topbar_btn}>

              <Button onClick={handleApprove} variant="contained" > Approve & Publish </Button>
              <Button onClick={handleRejectandDraft} variant="outlined" color="error"> Reject </Button>
            </Box>
          </Box>
        }
        <Box
          sx={{
            padding: '0px 60px 0px 60px',
            height: "100%",
            position: 'relative'
          }}
          ref={joditWrapperRef}
        >
          <TextField
            multiline
            sx={{
              backgroundColor: '#fff', position: 'absolute', resize: 'none',
              "& fieldset": { border: 'none' },
              "& .MuiOutlinedInput-input": { fontSize: '24px' },
              "& .Mui-disabled textarea": {
                WebkitTextFillColor: 'black', fontSize: '24px',
                fontWeight: '400'
              },
            }}
            className="custom_textarea"
            onKeyDown={handleKeyDown}
            onChange={(e) => { tabvalue !== 4 && handleChange(e) }}
            value={schedule.title}
            ref={textAreaRef}
            disabled={tabvalue !== 4 ? false : true}
          />
          {/* <button onClick={insertBase64Image}>Insert Base64 Image</button> */}

          <Drawer open={drawer} anchor={'right'} onClose={() => setDrawer(false)}
            sx={{
              "& .MuiBackdrop-root": {
                backgroundColor: "transparent",
              },
              "& .MuiPaper-root": {
                boxShadow: "none",
                border: '1px solid var(--border-color)',
                marginTop: '116px'
              },
            }}>
            {DrawerList}
          </Drawer>
          <JoditEditor
            ref={editorRef1}
            value={schedule?.content}
            config={tabvalue === 4 ? configtwo : config}
            onBlur={handleEditorChange}
          />
        </Box>
        <Modal
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '360px',
            borderRadius: '10px',
            padding: '30px',
            overflow: 'hidden',
          }}
          open={toggleImage}
          onClose={() => setToggleImage(false)}
          closeAfterTransition
        >
          <Box style={{
            position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
            width: '100%', height: '100%', padding: '30px', overflow: 'hidden', backgroundColor: '#d4e9ff', zIndex: 1
          }}>
            <Cropper
              ref={cropperRef}
              zoomTo={0}
              initialAspectRatio={16 / 9}
              style={{ background: 'transparent', height: '80%', width: "100%" }}
              src={image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={true}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              guides={true}
            />
            <Box style={{ display: 'flex', gap: '40px', justifyContent: 'space-around', marginTop: '30px' }}>
              <Button variant='contained' className={styles.submit} onClick={handleSignature}>Save</Button>
              <Button variant='contained' onClick={() => setToggleImage(false)} className={styles.cropper_cancel}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
        <ScheduleModal open={open} Schedule={(date) => handleSchedule(date)} setOpen={setOpen} scheduleTime={schedule.date} cancelSchedule={() => handleCancelSchedule()} tabvalue={tabvalue} />
        <ConfirmationModal tabvalue={tabvalue} confirmation={confirmation} setConfirmation={setConfirmation} id={schedule.id} type={type} postOptions={() => postOptions()} />
      </Box>
    </Box>
  )
};

export default Editor;