import { Tooltip } from "@mui/material"

const CommonToolTip = ({ title, placement, open, condition, component }) => {
    return (
        <Tooltip title={title} open={open} placement={placement} disableHoverListener={condition ? true : false} arrow componentsProps={{
            tooltip: {
                style: {
                    backgroundColor: 'var(--secondary)',
                    color: 'white',
                    fontSize: '.875rem',
                    padding: '.25rem .50rem',
                    borderRadius: '.3125rem'
                },
            },
            arrow: {
                style: {
                    color: 'var(--secondary)',
                },
            },
        }}>
            {component}
        </Tooltip>
    )
};

export default CommonToolTip;